import React, { useEffect, useState } from "react";

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import JSZip from "jszip";
import { PDFDocument } from "pdf-lib";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import Select from "react-select";

// Add this import
import { app, db } from "../firebase";
import { Order } from "../pages/OrderPage";
import { generateAndDownloadTailorNote } from "../tools/tailorNotes";

interface Tailor {
  id: string;
  name: string;
}

interface PendingTailorChange {
  orderId: string;
  oldTailor: string | undefined;
  newTailor: string;
}

interface SortOption {
  value: string;
  label: string;
}

export default function LogisticsOrderTailorDeligation() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [tailors, setTailors] = useState<Tailor[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(""); // Add this line
  const [sankeyData, setSankeyData] = useState<any[]>([["From", "To", "Weight"]]);
  const [deliveryMethodData, setDeliveryMethodData] = useState<any[]>([
    ["Delivery Method", "Count"],
  ]);
  const [retrieveMethodData, setRetrieveMethodData] = useState<any[]>([
    ["Retrieve Method", "Count"],
  ]);
  const [tailorOrdersData, setTailorOrdersData] = useState<any[]>([["Tailor", "Orders"]]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedRetrieveMethods, setSelectedRetrieveMethods] = useState<string[]>([]);
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState<string[]>([]);
  const [selectedTailors, setSelectedTailors] = useState<string[]>([]);
  const [orderToDelete, setOrderToDelete] = useState<string | null>(null);
  const [pendingTailorChange, setPendingTailorChange] =
    useState<PendingTailorChange | null>(null);
  const [pageOptions, setPageOptions] = useState<Array<{ value: string; label: string }>>(
    []
  );
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [activityOptions, setActivityOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [generatingPDFs, setGeneratingPDFs] = useState(false);
  const [sortBy, setSortBy] = useState<string>("oldest"); // Add this

  const sortOptions: SortOption[] = [
    { value: "oldest", label: "Oldest First" },
    { value: "newest", label: "Newest First" },
    { value: "activity-asc", label: "Activity Length (Shortest First)" },
    { value: "activity-desc", label: "Activity Length (Longest First)" },
  ];

  // Replace the handleTailorAssignment function
  const handleTailorAssignment = async (orderId: string, newTailorId: string) => {
    const order = orders.find((o) => o.id === orderId);
    if (order) {
      setPendingTailorChange({
        orderId,
        oldTailor: order.delegatedTailor,
        newTailor: newTailorId,
      });
    }
  };

  const confirmTailorChange = async () => {
    if (!pendingTailorChange) return;

    try {
      const orderRef = doc(db, "b2cOrders", pendingTailorChange.orderId);
      await updateDoc(orderRef, {
        delegatedTailor: pendingTailorChange.newTailor,
      });

      // Update local state
      setOrders(
        orders.map((order) =>
          order.id === pendingTailorChange.orderId
            ? { ...order, delegatedTailor: pendingTailorChange.newTailor }
            : order
        )
      );
      setPendingTailorChange(null);
    } catch (error) {
      console.error("Error updating delegated tailor:", error);
    }
  };

  // Add function to check for special IDs
  const isSpecialId = (id: string) => {
    const specialIds = [
      "ADMINBYPASS",
      "COURIERtoDropOffLocation",
      "TAILORtoFikseFerdig",
      "TRANSPORTERtoTailor",
    ];
    return specialIds.includes(id);
  };

  // Add unique values helper function
  const getUniqueValues = (array: Order[], key: "deliveryMethod" | "retrieveMethod") => {
    return Array.from(new Set(array.map((item) => item[key])));
  };

  // Add new helper functions
  const getFilteredCount = (
    key: "retrieveMethod" | "deliveryMethod",
    value: string,
    filteredOrders: Order[]
  ) => {
    return filteredOrders.filter((order) => order[key] === value).length;
  };

  const getTailorCount = (tailorId: string, filteredOrders: Order[]) => {
    if (tailorId === "no-tailor") {
      return filteredOrders.filter((order) => !order.delegatedTailor).length;
    }
    return filteredOrders.filter((order) => order.delegatedTailor === tailorId).length;
  };

  const handleDelete = async (orderId: string) => {
    try {
      await deleteDoc(doc(db, "b2cOrders", orderId));
      setOrderToDelete(null);
      // Refresh orders
    } catch (error) {
      console.error("Error deleting order:", error);
      alert("Failed to delete order");
    }
  };

  // Add this helper function after other helper functions
  const getPageName = (url: string | undefined) => {
    if (!url) return null;
    try {
      const urlWithoutHash = url.split("#")[0];
      const urlWithoutQuery = urlWithoutHash.split("?")[0];
      const segments = urlWithoutQuery.split("/").filter(Boolean);
      const lastSegment = segments[segments.length - 1];
      return lastSegment || null;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  // Add this helper function with other helpers
  const getLastActivity = (order: Order) => {
    if (!order.activity || order.activity.length === 0) return "No activity";
    return order.activity[order.activity.length - 1].content;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch tailors first
        const tailorsSnapshot = await getDocs(collection(db, "tailors"));
        const tailorList = tailorsSnapshot.docs
          .filter((doc) => !isSpecialId(doc.id))
          .map((doc) => ({
            id: doc.id,
            name: doc.data().name || doc.id,
          }));
        setTailors(tailorList);

        // Then fetch orders
        const ordersSnapshot = await getDocs(
          query(
            collection(db, "b2cOrders"),
            where("paid", "==", true),
            where("activity", "not-in", [
              { content: "orderStateDelivered" },
              { content: "Cancelled" },
              { content: "orderStateCancelled" },
            ]),
            orderBy("date", "asc")
          )
        );

        const fetchedOrders: Order[] = [];
        ordersSnapshot.forEach((doc) => {
          const data = doc.data() as Order;
          const lastActivity = data.activity?.[data.activity.length - 1]?.content;
          if (
            lastActivity !== "orderStateDelivered" &&
            lastActivity !== "Cancelled" &&
            lastActivity !== "orderStateCancelled"
          ) {
            fetchedOrders.push({ ...data, id: doc.id });
          }
        });

        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders);

        // Process Sankey data after both orders and tailors are available
        const flowMap = new Map<string, number>();
        fetchedOrders.forEach((order) => {
          const deliveryMethod = order.deliveryMethod;
          const tailorName =
            tailorList.find((t) => t.id === order.delegatedTailor)?.name ||
            "No Tailor Assigned";
          const retrieveMethod = order.retrieveMethod;

          const fromDelivery = deliveryMethod;
          const toRetrieve = retrieveMethod + " ";

          const deliveryToTailor = `${fromDelivery}->${tailorName}`;
          flowMap.set(deliveryToTailor, (flowMap.get(deliveryToTailor) || 0) + 1);

          const tailorToRetrieve = `${tailorName}->${toRetrieve}`;
          flowMap.set(tailorToRetrieve, (flowMap.get(tailorToRetrieve) || 0) + 1);
        });

        const sankeyDataArray: Array<[string, string, string | number]> = [
          ["From", "To", "Weight"],
        ];

        flowMap.forEach((weight, key) => {
          const [from, to] = key.split("->");
          sankeyDataArray.push([from, to, weight]);
        });

        setSankeyData(sankeyDataArray);
        console.log("Sankey data:", sankeyDataArray);

        // Process data for charts after orders are fetched
        const deliveryMethods = new Map<string, number>();
        const retrieveMethods = new Map<string, number>();
        const tailorOrders = new Map<string, number>();

        fetchedOrders.forEach((order) => {
          // Count delivery methods
          deliveryMethods.set(
            order.deliveryMethod,
            (deliveryMethods.get(order.deliveryMethod) || 0) + 1
          );

          // Count retrieve methods
          retrieveMethods.set(
            order.retrieveMethod,
            (retrieveMethods.get(order.retrieveMethod) || 0) + 1
          );

          // Count orders per tailor
          if (order.delegatedTailor) {
            const tailorName =
              tailorList.find((t) => t.id === order.delegatedTailor)?.name ||
              order.delegatedTailor;
            tailorOrders.set(tailorName, (tailorOrders.get(tailorName) || 0) + 1);
          }
        });

        // Convert to chart data format
        setDeliveryMethodData([
          ["Delivery Method", "Count"],
          ...Array.from(deliveryMethods).map(([method, count]) => [method, count]),
        ]);

        setRetrieveMethodData([
          ["Retrieve Method", "Count"],
          ...Array.from(retrieveMethods).map(([method, count]) => [method, count]),
        ]);

        // Convert to chart data format with reversed array order and numbers for values
        setTailorOrdersData([
          // First row defines column types
          [
            { type: "string", label: "Tailor" },
            { type: "number", label: "Orders" },
          ],
          // Data rows
          ...Array.from(tailorOrders).map(([tailor, count]) => [tailor, count]),
        ]);

        // After fetching orders, process page options
        const pages = new Set<string>();
        fetchedOrders.forEach((order) => {
          const pageName = getPageName(order.orderCreationUrl);
          if (pageName) pages.add(pageName);
        });

        setPageOptions(
          Array.from(pages).map((page) => ({
            value: page,
            label: page,
          }))
        );

        // After fetching orders, process activity options
        const activities = new Set<string>();
        fetchedOrders.forEach((order) => {
          const lastActivity = getLastActivity(order);
          if (lastActivity) activities.add(lastActivity);
        });

        setActivityOptions(
          Array.from(activities).map((activity) => ({
            value: activity,
            label: t(`workwearOrderPage.${activity}`) || activity,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array since we only want to fetch once

  // Add filter effect
  useEffect(() => {
    let result = [...orders];

    // Add search filter
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      result = result.filter(
        (order) =>
          order.id.toLowerCase().includes(searchLower) ||
          order.contactInfo?.name?.toLowerCase().includes(searchLower) ||
          order.contactInfo?.phone?.toLowerCase().includes(searchLower)
      );
    }

    if (selectedRetrieveMethods.length > 0) {
      result = result.filter((order) =>
        selectedRetrieveMethods.includes(order.retrieveMethod)
      );
    }

    if (selectedDeliveryMethods.length > 0) {
      result = result.filter((order) =>
        selectedDeliveryMethods.includes(order.deliveryMethod)
      );
    }

    if (selectedTailors.length > 0) {
      if (selectedTailors.includes("no-tailor")) {
        result = result.filter(
          (order) =>
            !order.delegatedTailor || selectedTailors.includes(order.delegatedTailor)
        );
      } else {
        result = result.filter(
          (order) =>
            order.delegatedTailor && selectedTailors.includes(order.delegatedTailor)
        );
      }
    }

    // Add page filtering
    if (selectedPages.length > 0) {
      result = result.filter((order) => {
        const pageName = getPageName(order.orderCreationUrl);
        return pageName && selectedPages.includes(pageName);
      });
    }

    // Add activity filtering
    if (selectedActivities.length > 0) {
      result = result.filter((order) => {
        const lastActivity = getLastActivity(order);
        return lastActivity && selectedActivities.includes(lastActivity);
      });
    }

    // Add sorting at the end of the filter effect
    result = sortOrders(result, sortBy);

    setFilteredOrders(result);
  }, [
    orders,
    selectedRetrieveMethods,
    selectedDeliveryMethods,
    selectedTailors,
    selectedPages,
    selectedActivities, // Add this
    searchQuery, // Add this
    sortBy, // Add this
  ]);

  // Add this sorting function
  const sortOrders = (orders: Order[], sortType: string) => {
    const sorted = [...orders];
    switch (sortType) {
      case "newest":
        return sorted.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      case "oldest":
        return sorted.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      case "activity-asc":
        return sorted.sort(
          (a, b) => (a.activity?.length || 0) - (b.activity?.length || 0)
        );
      case "activity-desc":
        return sorted.sort(
          (a, b) => (b.activity?.length || 0) - (a.activity?.length || 0)
        );
      default:
        return sorted;
    }
  };

  const getLastActivityStatus = (order: Order) => {
    if (!order.activity || order.activity.length === 0) return "No activity";

    if (order.activity[order.activity.length - 1].content == "Order created")
      return "Order created";

    return t(`workwearOrderPage.${order.activity[order.activity.length - 1].content}`);
  };

  const handleOrderSelection = (orderId: string) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
    );
  };

  const generateQRCodes = async () => {
    if (selectedOrders.length === 0) {
      alert("Please select at least one order");
      return;
    }

    setGeneratingPDFs(true);
    try {
      const functions = getFunctions(app, "europe-west1");
      const generatePDFs = httpsCallable(functions, "generateMultipleOrderQRCodes");

      const result = await generatePDFs({ orderIds: selectedOrders });
      const response = result.data as {
        success: boolean;
        pdfs: Array<{ orderId: string; itemIndex: number; pdf: string }>;
        error?: string;
      };

      if (!response.success || !response.pdfs) {
        throw new Error(response.error || "Failed to generate PDFs");
      }

      if (response.pdfs.length === 0) {
        throw new Error("No PDFs were generated");
      }

      // Create a new PDF document to merge all PDFs into
      const mergedPdf = await PDFDocument.create();

      // Add each PDF to the merged document
      for (const { pdf } of response.pdfs) {
        const pdfBytes = Uint8Array.from(atob(pdf), (c) => c.charCodeAt(0));
        const donorPdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          donorPdfDoc,
          donorPdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Save the merged PDF
      const mergedPdfBytes = await mergedPdf.save();

      // Create and trigger download
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `qr_codes_${new Date().toISOString().split("T")[0]}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error generating QR codes:", error);
      alert(error instanceof Error ? error.message : "Failed to generate QR codes");
    } finally {
      setGeneratingPDFs(false);
    }
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-lg font-semibold">Loading orders...</div>
      </div>
    );
  }

  // Handler for checkbox changes
  const handleCheckboxChange = (
    value: string,
    isChecked: boolean,
    setter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    setter((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold">{t("Orders")}</h1>
        {!window.location.pathname.includes("TailorDeligationDashboard") && (
          <button
            onClick={() => (window.location.href = "/TailorDeligationDashboard")}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            {t("Advanced Options")}
          </button>
        )}
      </div>

      {/* Charts and filters remain unchanged */}
      <div className="relative mb-8 h-[600px] w-full rounded border shadow">
        <div className="absolute inset-0" style={{ zIndex: 70 }}>
          <Chart
            chartType="Sankey"
            width="100%"
            height="100%"
            data={sankeyData}
            options={{
              tooltip: {
                textStyle: { fontSize: 14 },
                trigger: "both",
                isHtml: true, // Enable HTML tooltips
              },
              sankey: {
                link: {
                  interactivity: true,
                  tooltip: {
                    trigger: "both",
                    textStyle: { fontSize: 14 },
                  },
                },
                node: {
                  interactivity: true,
                  width: 20,
                  nodePadding: 10,
                },
              },
            }}
          />
        </div>
      </div>

      {/* Three charts side by side */}
      <div className="mb-8 grid grid-cols-3 gap-4">
        <div className="rounded border p-4 shadow">
          <Chart
            chartType="PieChart"
            data={deliveryMethodData}
            options={{
              title: "Delivery Methods Distribution",
              pieHole: 0.4,
              is3D: false,
            }}
            width="100%"
            height="400px"
          />
        </div>

        <div className="rounded border p-4 shadow">
          <Chart
            chartType="PieChart"
            data={retrieveMethodData}
            options={{
              title: "Retrieve Methods Distribution",
              pieHole: 0.4,
              is3D: false,
            }}
            width="100%"
            height="400px"
          />
        </div>

        <div className="rounded border p-4 shadow">
          <Chart
            chartType="BarChart"
            data={tailorOrdersData}
            options={{
              title: "Orders per Tailor",
              chartArea: { width: "50%" },
              hAxis: {
                title: "Number of Orders",
                minValue: 0,
                format: "0",
              },
              vAxis: {
                title: "Tailor",
              },
              legend: { position: "none" }, // Remove legend since it's clear what the bars represent
            }}
            width="100%"
            height="400px"
          />
        </div>
      </div>

      {/* Updated Multi-select filter section */}
      <div className="mb-8 grid grid-cols-3 gap-4">
        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Retrieve Methods</h3>
          <div className="max-h-48 overflow-y-auto">
            {getUniqueValues(orders, "retrieveMethod").map((method) => {
              const count = getFilteredCount("retrieveMethod", method, orders);
              return (
                <label
                  key={method}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedRetrieveMethods.includes(method)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        method,
                        e.target.checked,
                        setSelectedRetrieveMethods
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{method}</span>
                </label>
              );
            })}
          </div>
        </div>

        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Delivery Methods</h3>
          <div className="max-h-48 overflow-y-auto">
            {getUniqueValues(orders, "deliveryMethod").map((method) => {
              const count = getFilteredCount("deliveryMethod", method, orders);
              return (
                <label
                  key={method}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedDeliveryMethods.includes(method)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        method,
                        e.target.checked,
                        setSelectedDeliveryMethods
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{method}</span>
                </label>
              );
            })}
          </div>
        </div>

        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Tailors</h3>
          <div className="max-h-48 overflow-y-auto">
            {/* No Tailor Option */}
            <label
              className={`flex items-center space-x-2 p-1 ${
                getTailorCount("no-tailor", orders) === 0 ? "opacity-50" : ""
              }`}
            >
              <span className="w-8 text-right text-gray-500">
                {getTailorCount("no-tailor", orders)}
              </span>
              <input
                type="checkbox"
                checked={selectedTailors.includes("no-tailor")}
                onChange={(e) =>
                  handleCheckboxChange("no-tailor", e.target.checked, setSelectedTailors)
                }
                className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                disabled={getTailorCount("no-tailor", orders) === 0}
              />
              <span>No Tailor Assigned</span>
            </label>
            {/* Tailor Options */}
            {tailors.map((tailor) => {
              const count = getTailorCount(tailor.id, orders);
              return (
                <label
                  key={tailor.id}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedTailors.includes(tailor.id)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        tailor.id,
                        e.target.checked,
                        setSelectedTailors
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{tailor.name}</span>
                </label>
              );
            })}
          </div>
        </div>

        {/* Add this new filter section before or after existing filters */}
        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Page</h3>
          <Select
            isMulti
            options={pageOptions}
            onChange={(selected) =>
              setSelectedPages(selected ? selected.map((option) => option.value) : [])
            }
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select pages..."
          />
        </div>

        {/* Add this new filter section with the others */}
        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Last Activity</h3>
          <Select
            isMulti
            options={activityOptions}
            onChange={(selected) =>
              setSelectedActivities(
                selected ? selected.map((option) => option.value) : []
              )
            }
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select activities..."
          />
        </div>
      </div>

      {/* Add this button before the orders list */}
      {selectedOrders.length > 0 && (
        <div className="mb-4 flex justify-end">
          <button
            onClick={generateQRCodes}
            disabled={generatingPDFs}
            className="rounded bg-fikseBlue px-4 py-2 text-white hover:bg-blue-600 disabled:bg-gray-400"
          >
            {generatingPDFs
              ? "Generating..."
              : `Generate QR Codes (${selectedOrders.length})`}
          </button>
        </div>
      )}

      {/* Add sorting dropdown just before the search input */}
      <div className="mb-4 flex items-center gap-4">
        <div className="w-64">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="w-full rounded border border-gray-300 p-2 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search by customer name, phone, or order ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded border border-gray-300 p-2 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          />
        </div>
      </div>

      {/* Update the orders list to use filteredOrders */}
      <ul>
        {filteredOrders.map((order) => (
          <li key={order.id} className="mb-4 rounded border p-4 shadow">
            {/* Add checkbox at the beginning of each order item */}
            <div className="mb-4 flex items-start gap-2">
              <input
                type="checkbox"
                checked={selectedOrders.includes(order.id)}
                onChange={() => handleOrderSelection(order.id)}
                className="mt-1.5 h-4 w-4 rounded border-gray-300"
              />
              {/* Rest of the order item content */}
              <div className="flex-grow">
                {/* Add buttons at the top right */}
                <div className="mb-4 flex justify-end gap-2">
                  <button
                    onClick={() => window.open(`/a/${order.id.slice(0, 5)}`, "_blank")}
                    className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                  >
                    View Order
                  </button>
                  <button
                    onClick={() => setOrderToDelete(order.id)}
                    className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                  >
                    Delete Order
                  </button>
                </div>
                <div className="mb-2">
                  <strong>{t("Order ID")}: </strong>
                  <span>
                    {order.id.slice(0, 5)}
                    <span className="text-gray-500">{order.id.slice(5)}</span>
                  </span>
                </div>
                <div className="mb-2">
                  <strong>{t("Last Activity")}:</strong> {getLastActivityStatus(order)}
                </div>
                <div className="mb-2">
                  <strong>{t("Contact Name")}:</strong> {order.contactInfo.name}
                </div>
                <div className="mb-2">
                  <strong>{t("Contact Phone")}:</strong> {order.contactInfo.phone}
                </div>

                <div className="mb-2">
                  <strong>{t("Delivery Method")}:</strong> {order.deliveryMethod}
                </div>
                <div className="mb-2">
                  <strong>{t("Retrieve Method")}:</strong> {order.retrieveMethod}
                </div>
                <div className="mb-2">
                  <strong>{t("Order Date")}:</strong>{" "}
                  {new Date(order.date).toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </div>
                {!(order.paid == true) && (
                  <strong className="text-red-500">Order Not Paid</strong>
                )}

                <div className="mt-4 flex items-center justify-between gap-4">
                  <div className="flex-grow">
                    <label
                      htmlFor={`tailor-${order.id}`}
                      className="mb-2 block font-medium"
                    >
                      Assign Tailor:
                    </label>
                    <select
                      id={`tailor-${order.id}`}
                      className="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      value={order.delegatedTailor || ""}
                      onChange={(e) => handleTailorAssignment(order.id, e.target.value)}
                    >
                      <option value="">Select a tailor</option>
                      {tailors.map((tailor) => (
                        <option key={tailor.id} value={tailor.id}>
                          {tailor.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Update the orders list with new tailor note generation */}
                  <div className="flex flex-col gap-2">
                    {order.orderInfo.length === 1 ? (
                      <button
                        onClick={() => generateAndDownloadTailorNote(order.id)}
                        className="rounded bg-indigo-500 px-4 py-2 font-medium text-white hover:bg-indigo-600"
                      >
                        Generate Tailor Note
                      </button>
                    ) : (
                      order.orderInfo.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => generateAndDownloadTailorNote(order.id, index)}
                          className="rounded bg-indigo-500 px-4 py-2 font-medium text-white hover:bg-indigo-600"
                        >
                          Generate Note {index + 1}
                        </button>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Add confirmation modal */}
      {orderToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-lg font-bold">Confirm Deletion</h3>
            <p className="mb-6">
              Are you sure you want to delete order {orderToDelete.slice(0, 5)}? This
              action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setOrderToDelete(null)}
                className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(orderToDelete)}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add this before the closing div */}
      {pendingTailorChange && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-lg font-bold">Confirm Tailor Assignment</h3>
            <p className="mb-6">
              {pendingTailorChange.oldTailor
                ? `Update tailor from ${tailors.find((t) => t.id === pendingTailorChange.oldTailor)?.name || "Unknown"} to ${tailors.find((t) => t.id === pendingTailorChange.newTailor)?.name}`
                : `Set tailor to ${tailors.find((t) => t.id === pendingTailorChange.newTailor)?.name}`}
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setPendingTailorChange(null)}
                className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmTailorChange}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
