import { GeoPoint } from "firebase/firestore";

export enum RetailFlags {
  CUSTOM_SMS_SENDER = "customSmsSender",
  POSTAL_SERVICES = "postalServices",
}

export const RetailFlagDisplayNames: Record<RetailFlags, string> = {
  [RetailFlags.CUSTOM_SMS_SENDER]: "Send From Custom Sender",
  [RetailFlags.POSTAL_SERVICES]: "Postal Services",
};

export default interface Retail {
  id: string;
  name: string;
  orgNumber: string;
  addresses: string[];
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
  preVerifiedEmails: string[];
  moderatorEmails?: string[];
  location?: GeoPoint;
  margin?: number;
  flags: {
    [RetailFlags.CUSTOM_SMS_SENDER]: boolean;
    [RetailFlags.POSTAL_SERVICES]: boolean;
  };
  smsAlias?: string;
}
