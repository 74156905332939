import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const countryCodes = [
  "+47", // Norway
  "+46", // Sweden
  "+45", // Denmark
  "+358", // Finland
  "other", // Custom option
];

interface PhoneNumberInputProps {
  value: string;
  onChange: (value: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("+47");
  const [customCode, setCustomCode] = useState("+");

  const currentCountryCode = selectedOption === "other" ? customCode : selectedOption;
  const phoneWithoutCode = value.startsWith("+")
    ? value.substring(value.indexOf(" ") + 1)
    : value;

  const handlePhoneChange = (phone: string) => {
    onChange(`${currentCountryCode} ${phone}`);
  };

  const handleCustomCodeChange = (input: string) => {
    if (!input.startsWith("+")) input = "+" + input;
    setCustomCode(input);
    onChange(`${input} ${phoneWithoutCode}`);
  };

  return (
    <div className="flex">
      {selectedOption === "other" ? (
        <input
          type="text"
          value={customCode}
          onChange={(e) => handleCustomCodeChange(e.target.value)}
          className="text-md w-24 border-r border-t border-black bg-[#f7f6eb] p-4"
        />
      ) : (
        <select
          value={selectedOption}
          onChange={(e) => {
            const newCode = e.target.value;
            setSelectedOption(newCode);
            if (newCode !== "other") {
              onChange(`${newCode} ${phoneWithoutCode}`);
            }
          }}
          className="text-md border-r border-t border-black bg-[#f7f6eb] p-4"
        >
          {countryCodes.map((code) => (
            <option key={code} value={code}>
              {code === "other" ? t("other") : code}
            </option>
          ))}
        </select>
      )}
      <input
        type="text"
        value={phoneWithoutCode}
        onChange={(e) => handlePhoneChange(e.target.value)}
        placeholder={t("checkout.phoneNumber")}
        className="text-md block w-full border-t border-black bg-[#f7f6eb] p-4"
      />
    </div>
  );
};

export default PhoneNumberInput;
