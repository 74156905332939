import { Fragment, useEffect, useState } from "react";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Import i18n hook
import { useData } from "../DataContext";
import { signOutUser } from "../auth";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as XMarkIcon } from "../images/icons-fikse/close.svg";
import { ReactComponent as HamburgerIcon } from "../images/icons-fikse/hamburger.svg";
import { accountHasAccessToDashboard } from "../pages/DashboardPage";
import useAuth from "../useAuth";
import SignInWorkaround from "./SignInWorkaround";
import OrderModal from "./newOrder/OrderModal";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { userData, setUserId } = useData();
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { i18n } = useTranslation(); // Destructure i18n
  const [hasDashboardAccess, setHasDashboardAccess] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogoutOrLogin = () => {
    if (localStorage.getItem("uid")) {
      localStorage.setItem("uid", "");
      localStorage.setItem("verifiedUid", "");
      localStorage.setItem("department", "");
      signOutUser();
      window.location.reload();
    } else {
      setShowSignIn(true);
    }
    setMenuVisible(false);
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === "no" ? "en" : "no";
    i18n.changeLanguage(newLang);
  };

  const user = useAuth();
  const { t } = useTranslation();

  const handleNavigateToHomePage = () => {
    window.location.href = "https://fiksefirstapp.web.app/";
  };

  const handleLogoClick = () => {
    // Redirect to the root of the current domain or subdomain
    window.location.href = `${window.location.origin}/`;
  };

  function getFormattedEmail(): string {
    // Retrieve the email from localStorage
    const email = localStorage.getItem("verifiedUid");

    if (!email) {
      return "";
    }
    // Strip the @ and everything to the right
    const strippedEmail = email.split("@")[0];

    // Replace "." with spaces
    const formattedEmail = strippedEmail.replace(/\./g, " ");

    return formattedEmail;
  }

  const urlPath = useLocation().pathname;
  const hideFikseLogo = urlPath !== "/" && urlPath !== "/dashboard";

  // Add useEffect to check dashboard access when user changes
  useEffect(() => {
    const checkDashboardAccess = async () => {
      const email = localStorage.getItem("uid");
      if (email) {
        const hasAccess = await accountHasAccessToDashboard(email);
        setHasDashboardAccess(hasAccess);
      } else {
        setHasDashboardAccess(false);
      }
    };

    checkDashboardAccess();
  }, [user]); // Depend on user to recheck when auth state changes

  return (
    <Disclosure as="nav" className="z-20 bg-white">
      {({ open }: any) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center justify-between">
                {hideFikseLogo && (
                  <div
                    className="flex cursor-pointer md:justify-start"
                    onClick={handleLogoClick}
                  >
                    <FikseLogo className="scale- transform" height="25px" />
                  </div>
                )}
                {user && (
                  <p className="text-s pl-4 sm:pl-7 sm:text-base">
                    {t("mobileView.greeting")} {getFormattedEmail()}.
                  </p>
                )}
              </div>

              <div className="flex items-center">
                <div className="md:hidden">
                  <button
                    onClick={toggleMenu}
                    className="inline-flex items-center justify-center p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#8CD7FF]"
                  >
                    <span className="sr-only">Open main menu</span>
                    {menuVisible ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      //  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      <HamburgerIcon aria-hidden="true" />
                    )}
                  </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  {localStorage.getItem("uid") ? (
                    <>
                      {/*<button
                      type="button"
                      className="gap-x-1. relative inline-flex items-center border border-black px-3 py-2 text-sm shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                      onClick={handleLogoutOrLogin}
                    >
                      {localStorage.getItem("uid")}
                    </button>*/}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="gap-x-1. relative inline-flex items-center border border-black px-3 py-2 text-sm shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                      onClick={handleLogoutOrLogin}
                    >
                      {t("navBar.logIn")}
                    </button>
                  )}
                  <Menu as="div" className="z-15 relative ml-3">
                    <div>
                      <Menu.Button className="relative flex bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#8CD7FF] focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <HamburgerIcon aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-3 z-20 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {(hasDashboardAccess ||
                          localStorage
                            .getItem("uid")
                            ?.endsWith("mari.guttormsen@uke.oslo.kommune.no")) && (
                          <Menu.Item>
                            {({ active }: any) => (
                              <a
                                href="/dashboard"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block border-b border-black px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Dashboard
                              </a>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href="#about"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block border-b border-black px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              About us
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href="/locations"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block border-b border-black px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Our locations
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href="#pricing"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block border-b border-black px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Pricing
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              onClick={handleLogoutOrLogin}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block cursor-pointer border-b border-black px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {localStorage.getItem("uid") ? "Log out" : "Log in"}
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu state */}
          <div
            className={`fixed left-0 top-0 z-40 w-full bg-white shadow-lg transition-transform duration-700 ease-in-out ${
              menuVisible
                ? "translate-y-0 transform opacity-100"
                : "-translate-y-full transform opacity-0"
            }`}
          >
            <div className="flex items-center justify-between border-b border-gray-200 p-4">
              <span className="text-lg">
                <FikseLogo className="h-auto w-12" />
              </span>
              <button
                onClick={toggleMenu}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex flex-col px-4">
              <a
                href="#about"
                className="flex items-center justify-between border-b py-4 text-left text-xl"
              >
                About us
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <a
                href="#locations"
                className="flex items-center justify-between border-b py-4 text-left text-xl"
              >
                Our locations
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <a
                href="#pricing"
                className="flex items-center justify-between border-b py-4 text-left text-xl"
              >
                Pricing
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <a
                href="#business"
                className="flex items-center justify-between border-b py-4 text-left text-xl"
              >
                Become a business customer
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <a
                href="#tailor"
                className="flex items-center justify-between border-b py-4 text-left text-xl"
              >
                Become a tailor
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <a
                onClick={handleLogoutOrLogin}
                className="flex cursor-pointer items-center justify-between border-b py-4 text-left text-xl"
              >
                {localStorage.getItem("uid") ? "Log out" : "Log in"}
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              {/* Language Toggle Button */}
              <a
                onClick={toggleLanguage}
                className="flex cursor-pointer items-center justify-between border-b py-4 text-left text-xl"
              >
                {i18n.language === "no" ? "English" : "Norsk"}
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
            </div>
          </div>

          {isOrderModalOpen && (
            <OrderModal
              open={isOrderModalOpen}
              onClose={() => setOrderModalOpen(false)}
            />
          )}
          {showSignIn && (
            <SignInWorkaround open={showSignIn} onClose={() => setShowSignIn(false)} />
          )}
        </>
      )}
    </Disclosure>
  );
}
