// auth.ts
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  reload,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { string } from "prop-types";

import { app, db } from "./firebase";

const auth = getAuth(app);

const connectRetailStoreWithLocalStorge = async (email: string) => {
  const retailCollection = collection(db, "retail");
  const q = query(retailCollection, where("preVerifiedEmails", "array-contains", email));
  const querySnapshot = await getDocs(q);
  const documentNames: string[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
    documentNames.push(doc.id);
  });
  const namesString = documentNames.join(",");
  localStorage.setItem("retailConnections", namesString);
};

export const connectRolesWithLocalStorge = async (email: string) => {
  const usersCollection = collection(db, "tailors");
  const q = query(usersCollection, where("preVerifiedEmails", "array-contains", email));

  const querySnapshot = await getDocs(q);

  let allRoles: string[] = [];
  // Get existing roles from localStorage if any
  const existingRoles = localStorage.getItem("roles");
  if (existingRoles) {
    allRoles = existingRoles.split(",");
  }

  querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
    const userData = doc.data();

    if (userData.roles && Array.isArray(userData.roles)) {
      // Add new roles, excluding duplicates
      userData.roles.forEach((role: string) => {
        if (!allRoles.includes(role)) {
          allRoles.push(role);
        }
      });
    }
  });

  localStorage.setItem("roles", allRoles.join(","));
};

export const synchronizedUser = async (user: any) => {
  localStorage.setItem("uid", user.email);
  localStorage.setItem("verifiedUid", user.email);

  // Reference to the user's document in Firestore
  const userDocRef = doc(db, "users", user.email);
  const userDocSnap = await getDoc(userDocRef);
  const userData = userDocSnap.data();

  if (userData) {
    localStorage.setItem("roles", userData.roles);
    localStorage.setItem("retailConnections", userData.retailConnections);

    // Update the uids array if the current uid isn't included
    if (!userData.uids?.includes(user.uid)) {
      await setDoc(userDocRef, {
        ...userData,
        uids: [...(userData.uids || []), user.uid],
      });
    }
  } else {
    // Create a new document for the user
    await setDoc(userDocRef, {
      email: user.email,
      uids: [user.uid],
      roles: [],
      // Add other user-specific fields as needed
    });
  }

  await connectRetailStoreWithLocalStorge(user.email);
  await connectRolesWithLocalStorge(user.email);

  // Check if user has retail connections and redirect
  const retailConnections = localStorage.getItem("retailConnections");
  console.log("XXretailConnections: Outside", retailConnections);

  if (
    retailConnections &&
    retailConnections.length > 0 &&
    !window.location.pathname.includes("/dashboard")
  ) {
    console.log("XXretailConnections: Inside", retailConnections);
    // Use setTimeout to ensure all other operations are complete before redirect

    window.location.replace("/brands");

    return; // Add return to prevent any further execution
  }
  window.location.reload();
};

export const signInWithMicrosoft = async () => {
  const provider = new OAuthProvider("microsoft.com");

  try {
    const result = await signInWithPopup(auth, provider);
    // User is signed in.
    const user = result.user;
    // Access tokens
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential?.accessToken;
    const idToken = credential?.idToken;
    console.log("User signed in:", user.email);

    if (user.email) {
      console.log("Insid user email");
      await synchronizedUser(user);
    }
    //Ved testing av uthenting til microsoft, fjerne denne kommentarten og sjekk hva du får ut av data
    //await getMicrosoftUserInfo();

    // Handle user information and tokens as needed
  } catch (error) {
    // Handle errors here
    console.error("Authentication error:", error);
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    // Access tokens
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const accessToken = credential?.accessToken;
    console.log("User signed in:", user.email);

    if (user.email) {
      await synchronizedUser(user);
    }
  } catch (error) {
    console.error("Authentication error:", error);
  }
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("roles");
    localStorage.removeItem("uid");
    localStorage.removeItem("verifiedUid");
    localStorage.removeItem("retailConnections");

    // Sign-out successful.
  } catch (error) {
    // An error happened.
    console.error("Sign-out error:", error);
  }
};

export const getCurrentUserEmail = () => {
  const auth = getAuth();
  return auth.currentUser?.email || "Anonymous";
};

export const getCurrentUserEmailAsync = async (): Promise<string | null> => {
  // Wait for auth state to be ready
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe(); // Clean up the listener
      resolve(user?.email || null);
    });
  });
};

export const signInWithEmailAndPasswordFirebase = async (
  email: string,
  password: string
) => {
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      if (user.email) {
        await synchronizedUser(user);
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};

async function checkEmailHasPasswordAuth(email: string) {
  try {
    const auth = getAuth(app);

    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    console.log("Available sign-in methods:", signInMethods);
    console.log("XXhasPassword:", signInMethods);

    // "password" indicates email/password authentication
    return signInMethods.includes("password");
  } catch (error) {
    console.error("Error checking sign-in methods:", error);
    return false;
  }
}

export const checkIfUserHasPassword = async (
  email: string
): Promise<{
  hasPassword: boolean;
  isVerified: boolean;
  hasMicrosoftButNoPassword: boolean;
}> => {
  try {
    // Initialize Firebase Functions
    const functions = getFunctions();
    const checkVerification = httpsCallable(functions, "checkEmailVerification");

    // Get verification status using cloud function
    const verificationResult = await checkVerification({ email });
    const { success, exists, isVerified, hasPassword, hasMicrosoft } =
      verificationResult.data as {
        success: true;
        exists: false;
        isVerified: false;
        hasPassword: false;
        hasMicrosoft: false;
      };
    console.log("XXverificationResult:", verificationResult);

    // Get sign in methods
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    console.log("Available sign-in methods:", signInMethods);

    console.log("XXhasPassword:", hasPassword);

    return {
      hasPassword: success ? hasPassword : false,
      isVerified: success ? isVerified : false,
      hasMicrosoftButNoPassword: success ? hasMicrosoft && !hasPassword : false,
    };
  } catch (error) {
    console.error("Error checking user status:", error);
    return {
      hasPassword: false,
      isVerified: false,
      hasMicrosoftButNoPassword: false,
    };
  }
};
// Function to create a new user with email verification
export const createUserWithVerification = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await signOut(auth);

    console.log("XXUser created");
    const user = userCredential.user;

    //await sendEmailVerification(user);
    await sendNewVerificationEmail(email);

    const userDocRef = doc(db, "users", user.email!);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();

    if (userData) {
      // Update the uids array if the current uid isn't included
      if (!userData.uids?.includes(user.uid)) {
        await setDoc(userDocRef, {
          ...userData,
          uids: [...(userData.uids || []), user.uid],
        });
      }
    } else {
      // Create a new document for the user
      await setDoc(userDocRef, {
        email: user.email,
        uids: [user.uid],
        roles: [],
        emailVerified: false,
      });
    }

    return {
      success: true,
      message: "Verification email sent. Please check your inbox.",
    };
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const signInWithEmailPassword = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    if (user.email) {
      await synchronizedUser(user);
      return { success: true };
    }
    return { success: false, error: "No email found" };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const sendNewVerificationEmail = async (email: string) => {
  try {
    const functions = getFunctions();
    const sendVerification = httpsCallable(
      functions,
      "sendVerificationEmailForPasswordSetup"
    );
    const result = await sendVerification({ email: email, href: window.location.href });
    console.log("XXsendVerification:", email);

    return result.data;
  } catch (error: any) {
    console.error("Error:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

export const sendPasswordResetEmailHandler = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      success: true,
      message: "Password reset email sent. Please check your inbox.",
    };
  } catch (error: any) {
    return {
      success: false,
      message: error.message,
    };
  }
};

/*SLETT DENNE*/
export const getMicrosoftUserInfo = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    return null;
  }

  // Find Microsoft provider data
  const tokenResult = await user.getIdTokenResult();
  const microsoftProvider = user.providerData;

  console.log("XXMmicrosoftProvider:", microsoftProvider);
  console.log("XXMtTokenResult:", tokenResult);

  if (!microsoftProvider) {
    return null;
  }

  return {
    microsoftProvider,
    // Additional Microsoft-specific claims can be accessed through getIdTokenResult
    // Example usage:
    // const tokenResult = await user.getIdTokenResult();
    // const microsoftClaims = tokenResult.claims;
    tokenResult,
  };
};

//Claude microsoft graphAPI start
let microsoftAccessToken: string | null = null;

export const getMicrosoftGraphInfo = async () => {
  console.log("Inside getMicrosoftGraphInfo function");
  try {
    const user = auth.currentUser;
    if (!user) return null;

    const provider = user.providerData.find(
      (provider) => provider.providerId === "microsoft.com"
    );

    if (!provider) {
      console.error("Ingen Microsoft provider funnet");
      return null;
    }

    if (!microsoftAccessToken) {
      console.error("Ingen Microsoft access token tilgjengelig");
      return null;
    }

    const response = await fetch("https://graph.microsoft.com/v1.0/me", {
      headers: {
        Authorization: `Bearer ${microsoftAccessToken}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Graph API svarte med status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Microsoft Graph data:", data);

    return data;
  } catch (error) {
    console.error("Feil ved henting av Microsoft Graph data:", error);
    return null;
  }
};

export const signInWithMicrosoftHighDataRetrical = async () => {
  const provider = new OAuthProvider("microsoft.com");

  // Legg til nødvendige scopes
  provider.addScope("User.Read");
  provider.addScope("User.Read.All");

  // Sett opp custom parameters hvis nødvendig
  provider.setCustomParameters({
    // prompt: 'consent', // Tving ny godkjenning hvis nødvendig
  });

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const credential = OAuthProvider.credentialFromResult(result);

    // Store the access token
    microsoftAccessToken = credential?.accessToken || null;

    // Store token in Firestore for persistence
    if (user.email && credential?.accessToken) {
      const userDocRef = doc(db, "users", user.email);
      await setDoc(
        userDocRef,
        {
          microsoftAccessToken: credential.accessToken,
          lastTokenUpdate: new Date().toISOString(),
        },
        { merge: true }
      );
    }

    if (user.email) {
      await synchronizedUser(user);
    }

    try {
      const microsoftData = await getMicrosoftGraphInfo();

      if (microsoftData && user.email) {
        const userDocRef = doc(db, "users", user.email);
        await setDoc(
          userDocRef,
          {
            microsoftData,
            lastGraphApiSync: new Date().toISOString(),
          },
          { merge: true }
        );

        console.log("Lagret Microsoft data:", microsoftData);
      }
    } catch (graphError) {
      console.warn("Kunne ikke hente Microsoft Graph info:", graphError);

      if (user.email) {
        const userDocRef = doc(db, "users", user.email);
        await setDoc(
          userDocRef,
          {
            lastGraphApiError: {
              timestamp: new Date().toISOString(),
              message: graphError instanceof Error ? graphError.message : "Unknown error",
            },
          },
          { merge: true }
        );
      }
    }

    return {
      success: true,
      user,
      credential,
    };
  } catch (error) {
    console.error("Autentiseringsfeil:", error);
    microsoftAccessToken = null; // Clear the token if sign-in fails
    return {
      success: false,
      error,
    };
  }
};

// Hjelpefunksjon for retry forblir den samme
export const retryGetMicrosoftGraphInfo = async (userEmail: string) => {
  try {
    const microsoftData = await getMicrosoftGraphInfo();

    if (microsoftData) {
      const userDocRef = doc(db, "users", userEmail);
      await setDoc(
        userDocRef,
        {
          microsoftData,
          lastGraphApiSync: new Date().toISOString(),
        },
        { merge: true }
      );

      return { success: true, data: microsoftData };
    }
    return { success: false, error: "Ingen data tilgjengelig" };
  } catch (error) {
    console.error("Retry av Graph API feilet:", error);
    return { success: false, error };
  }
};
//Claude microsoft graphAPI end
