import React, { useEffect, useState } from "react";

import { set } from "date-fns";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { use } from "i18next";
import { title } from "process";
import { useTranslation } from "react-i18next";

import { signOutUser } from "../../auth";
import { db } from "../../firebase";
import i18n from "../../i18n";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import ModalTile from "../ModalTile";
import SignInWorkaround from "../SignInWorkaround";
import {
  eligibleForPostalFromRetailUUID,
  retailAdressesFromRetailUUID,
} from "../databaseHelper";
import OrderModal from "../newOrder/OrderModal";
import WorkwearModal from "../newOrder/WorkwearModal";

interface OrderModalButtonsProps {
  categories: string[];
  discountPassTrough?: string;
  attributes?: string[];
}

export const OrderModalButtons = ({
  categories,
  discountPassTrough,
  attributes,
}: OrderModalButtonsProps) => {
  const { t } = useTranslation();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showWorkwearModal, setShowWorkwearModal] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [logginModal, setLogginModal] = useState(false);
  const [officeEmail, setOfficeEmail] = useState("");
  const [serviceCategoryBuffer, setServiceCategoryBuffer] = useState("");
  const [notValidOfficeEmail, setNotValidOfficeEmail] = useState(false);
  const [validatedEmail, setValidatedEmail] = useState(
    attributes?.includes("Office") ? true : false
  );
  const [qualifyForPostalService, setQualifyForPostalService] = useState(false);
  const [officeData, setOfficeData] = useState({});
  const [customDropOffBuffer, setCustomDropOffBuffer] = useState<string[]>([]);
  const [showNoBrandsModal, setShowNoBrandsModal] = useState(false);

  const officeEmailsDomains = ["nrk.no"];
  const logginDomains = [
    "avtale.fikse.co/offices",
    "dev.avtale.fikse.co:3000/offices",
    "fiksefirstapp.web.app/offices",
    "localhost:3000/offices",
  ];

  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const firebaseDB = doc(db, "dynamicPages", "office");
        const officeAgreementsBuffer = await getDoc(firebaseDB);
        if (officeAgreementsBuffer.exists()) {
          setOfficeData(officeAgreementsBuffer.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching office data:", error);
      }
    };

    fetchOfficeData();
  }, []);

  useEffect(() => {
    const currentDomain = window.location.href.replace(/^https?:\/\//, "").split("#")[0];
    const isOfficeDomain = currentDomain.includes("/offices");

    if (isOfficeDomain) {
      setLogginModal(true);
    }
  }, []);

  useEffect(() => {
    console.log("xxxx", officeData);
  }, [officeData]);

  useEffect(() => {
    if (serviceCategoryBuffer) handleCategoryClick(serviceCategoryBuffer);
  }, [validatedEmail]);

  const handleCloseModal = (closeModalFn: () => void) => {
    setTimeout(() => {
      closeModalFn();
    }, 300);
  };
  const isWorkWearDomain: boolean = (() => {
    const workwearDomains = ["oslo.kommune.fikse.co", "osloskolen.fikse.co"];

    return workwearDomains.some((domain) => window.location.hostname.includes(domain));
  })();

  const checkOfficeEmail = () => {
    // Special case for Forskingparken
    //TODO: Remove this when we have a better solution
    if (officeEmail?.toLowerCase() === "forskningsparken") {
      setCustomDropOffBuffer(["Forskningsparken"]);
      return true;
    }

    // Handle empty email case
    if (!officeEmail) return false;

    const localEmailDomain = (officeEmail.split("@")[1] ?? "").trim();

    // Handle case when officeData entries are empty
    if (!officeData || typeof officeData !== "object") {
      console.warn("Office data is not loaded properly");
      return false;
    }

    return Object.entries(officeData).some(([office, data]: [string, any]) => {
      // Check if data and emailDomains exist and are valid
      if (!data || !Array.isArray(data.emailDomains)) {
        console.warn(`Invalid data structure for office ${office}`);
        return false;
      }

      if (data.emailDomains.includes(localEmailDomain)) {
        // Make sure location exists before setting it
        if (Array.isArray(data.location)) {
          setCustomDropOffBuffer(data.location);
          return true;
        }
      }
      return false;
    });
  };

  const handleCategoryClick = async (category: string) => {
    const currentDomain = window.location.href.replace(/^https?:\/\//, "").split("#")[0];
    localStorage.setItem("officeEmail", "");
    console.log("atributes", attributes);
    if (validatedEmail) {
      setServiceCategoryBuffer(category);
      setLogginModal(true);
      return;
    }
    if (attributes?.includes("Brands") && !localStorage.getItem("verifiedUid")) {
      setShowSignIn(true);
      return;
    }

    //Check for Brands page
    if (attributes?.includes("Brands")) {
      if (localStorage.getItem("verifiedUid")) {
        const retailConnections =
          // Split application memory into an array and remove "undefined" and empty elements
          (localStorage.getItem("retailConnections")?.split(",") || []).filter(
            (connection) => connection !== "undefined" && connection !== ""
          );
        console.log("retailConnections", retailConnections);
        if (retailConnections.length == 0) {
          setShowNoBrandsModal(true);
          return;
        } else {
          const retailAddresses = await retailAdressesFromRetailUUID(retailConnections);
          setCustomDropOffBuffer(retailAddresses);

          setQualifyForPostalService(
            await eligibleForPostalFromRetailUUID(retailConnections)
          );
        }
      } else {
        setShowSignIn(true);
        return;
      }
    }

    let serviceCategory = "";
    switch (category) {
      case "Clothes":
        serviceCategory = i18n.language === "no" ? "NO-Clothes-0924" : "EN-Clothes-0924";
        break;
      case "Shoes":
        serviceCategory = i18n.language === "no" ? "NO-Shoes-0924" : "EN-Shoes-0924";
        break;
      case "Accessories":
        serviceCategory =
          i18n.language === "no" ? "NO-Accessories-0924" : "EN-Accessories-0924";
        break;
      case "Bunad":
        serviceCategory = i18n.language === "no" ? "NO-Bunad-0924" : "EN-Bunad-0924";
        break;
      case "Workwear":
        serviceCategory =
          i18n.language === "no"
            ? "NO-Workwear/Ahlsell-0924"
            : "EN-Workwear/Ahlsell-0924";
        break;
      case "OtherTextiles":
        serviceCategory =
          i18n.language === "no" ? "NO-OtherTextiles-1124" : "EN-OtherTextiles-1124";
        break;
      default:
        serviceCategory = "EN-Clothes-0924";
    }

    localStorage.setItem("serviceCategory", serviceCategory);
    if (category === "Workwear") {
      setShowWorkwearModal(true);
    } else {
      setShowOrderModal(true);
    }
  };

  const isWorkWear: boolean = (() => {
    const email = localStorage.getItem("verifiedUid");
    //  if (window.location.hostname === "oslo.kommune.fikse.co") return true;
    return email
      ? email.endsWith("oslo.kommune.no") || email.endsWith("osloskolen.no") //fjerner normale tøy for oslo kommune
      : false;
  })();

  const handleEmailSubmit = () => {
    if (officeEmail) {
      localStorage.setItem("officeEmail", officeEmail);
      setLogginModal(false);
    }
  };

  return (
    <>
      {!isWorkWear && !isWorkWearDomain && (
        <>
          {categories.includes("Clothes") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-t border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Clothes")}
            >
              <span>{t("mobileView.clothes")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Shoes") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Shoes")}
            >
              <span>{t("mobileView.shoes")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Accessories") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Accessories")}
            >
              <span>{t("mobileView.accessories")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Bunad") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Bunad")}
            >
              <span>{t("mobileView.bunad")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
        </>
      )}

      {categories.includes("Workwear") && (
        <a
          href="#"
          className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
          onClick={() => handleCategoryClick("Workwear")}
        >
          <span>{t("mobileView.workwear")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
      )}
      {categories.includes("OtherTextiles") && (
        <a
          href="#"
          className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
          onClick={() => handleCategoryClick("OtherTextiles")}
        >
          <span>{t("mobileView.otherTextiles")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
      )}
      <SignInWorkaround
        open={showSignIn}
        onClose={() => handleCloseModal(() => setShowSignIn(false))}
      />
      <OrderModal
        open={showOrderModal}
        onClose={() => handleCloseModal(() => setShowOrderModal(false))}
        discount={discountPassTrough}
        emailPassedTrough={!notValidOfficeEmail ? officeEmail : undefined}
        customDropOff={customDropOffBuffer.length ? customDropOffBuffer : undefined}
        attributes={[
          ...(attributes ?? []),
          ...(qualifyForPostalService ? ["enablePostalService"] : []),
        ]}
      />
      <WorkwearModal
        open={showWorkwearModal}
        onClose={() =>
          handleCloseModal(() => {
            setShowWorkwearModal(false);

            isWorkWear && setShowOrderModal(true);
          })
        }
      />
      <ModalTile
        open={logginModal}
        onClose={() => {
          setLogginModal(false);

          setTimeout(() => {
            setNotValidOfficeEmail(false);
          }, 300);
        }}
        title="Din arbeidsplass"
      >
        <div>
          {notValidOfficeEmail && (
            <div className="px-6 pb-4 text-lg">
              Arbeidsplass-koden eller E-posten du har skrevet er ikke en del av Fikse
              kontoravtale. Sjekk at du har skrevet den riktig, eller kontakt oss på
              hei@fikse.co
            </div>
          )}
          <input
            type="text"
            placeholder="Skriv inn din arbeidsplass eller jobb epost her"
            className={`text-md block w-full border-t border-black bg-${notValidOfficeEmail ? "fikseRed" : "fikseBeige"} p-4 px-6`}
            value={officeEmail}
            onChange={(e) => setOfficeEmail(e.target.value)}
          />
          <button
            type="button"
            onClick={() => {
              if (checkOfficeEmail()) {
                setNotValidOfficeEmail(false);
                setLogginModal(false);
                setValidatedEmail(false);
              } else {
                setServiceCategoryBuffer("");
                setNotValidOfficeEmail(true);
              }
            }}
            className={`mt-auto flex w-full flex-row justify-between rounded-none border-b border-t border-black bg-fikseGreen px-6 py-4 text-left text-lg`}
          >
            Forsett
            <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
      </ModalTile>
      <ModalTile
        open={showNoBrandsModal}
        onClose={() => setShowNoBrandsModal(false)}
        title="Ingen brands tilknyttet epost"
      >
        <div className="flex flex-col">
          <p className="mb-6 p-6 text-lg">
            Ingen brands ble knyttet til e-posten: {localStorage.getItem("verifiedUid")}
          </p>
          <button
            onClick={async () => {
              await signOutUser();
              window.location.reload();
            }}
            className="mt-auto flex w-full flex-row justify-between rounded-none border-b border-t border-black bg-fikseGreen px-6 py-4 text-left text-lg"
          >
            Log out
            <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
      </ModalTile>
    </>
  );
};
