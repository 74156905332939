// Importer nødvendige Firebase-funksjoner
import { useEffect, useState } from "react";

import { GeoPoint, Timestamp, addDoc, collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import Retail, { RetailFlagDisplayNames, RetailFlags } from "../../types/retail";
import { EditRetailModal } from "./EditRetailModal";

// Importer modal komponenten

export default function RetailOverview() {
  const [retailers, setRetailers] = useState<Retail[]>([]);
  const [stats, setStats] = useState({
    totalRetailers: 0,
    multipleAddresses: 0,
  });
  const [selectedRetailer, setSelectedRetailer] = useState<Retail | null>(null);

  useEffect(() => {
    const fetchRetailers = async () => {
      const querySnapshot = await getDocs(collection(db, "retail"));
      const retailerList: Retail[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Retail[];

      setRetailers(retailerList);

      const totalRetailers = retailerList.length;
      const multipleAddresses = retailerList.filter(
        (retailer) => retailer.addresses.length > 1
      ).length;

      setStats({ totalRetailers, multipleAddresses });
    };

    fetchRetailers();
  }, []);

  const handleAddRetailor = async () => {
    const name = prompt("Enter retailer name:");
    if (!name) return;

    const orgNumber = prompt("Enter organization number:");
    if (!orgNumber) return;

    const newRetail = {
      name,
      orgNumber,
      addresses: [],
      contactPerson: {
        name: "",
        phone: "",
        email: "",
      },
      preVerifiedEmails: [],
      flags: {
        [RetailFlags.CUSTOM_SMS_SENDER]: false,
        [RetailFlags.POSTAL_SERVICES]: false,
      },
    };

    try {
      const docRef = await addDoc(collection(db, "retail"), newRetail);
      setRetailers([...retailers, { ...newRetail, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding retail:", error);
    }
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Retailers</h2>

      <div className="mb-6 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-4">
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Total number of retailers</div>
          <div className="text-2xl font-bold text-gray-900">{stats.totalRetailers}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Retailers with multiple addresses</div>
          <div className="text-2xl font-bold text-gray-900">
            {stats.multipleAddresses}
          </div>
        </div>
      </div>

      <ul role="list" className="grid grid-cols-1 gap-0 lg:grid-cols-4 xl:gap-0">
        {retailers.map((retailer) => (
          <li
            key={retailer.id}
            className="overflow-hidden border border-black border-gray-200 p-6"
          >
            <h3 className="text-lg font-bold text-gray-900">{retailer.name}</h3>
            <p className="text-sm text-gray-500">Org no: {retailer.orgNumber}</p>
            <p className="text-xs text-gray-400">UUID: {retailer.id}</p>
            {retailer.margin && (
              <p className="text-xs text-gray-400">Margin: {retailer.margin * 100}%</p>
            )}{" "}
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Addresses:</h4>
              {retailer.addresses.map((address, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {address}
                </p>
              ))}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Contact Person:</h4>
              <p className="text-sm text-gray-500">Name: {retailer.contactPerson.name}</p>
              <p className="text-sm text-gray-500">
                Phone: {retailer.contactPerson.phone}
              </p>
              <p className="text-sm text-gray-500">
                Email: {retailer.contactPerson.email}
              </p>
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">
                Pre-verified Emails:
              </h4>
              {retailer.preVerifiedEmails?.map((email, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {email}
                </p>
              ))}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Moderator Emails:</h4>
              {retailer.moderatorEmails?.map((email, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {email}
                </p>
              ))}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Functions:</h4>
              {retailer.flags &&
                Object.entries(retailer.flags).map(([key, value]) => (
                  <p key={key} className="text-sm text-gray-500">
                    <span className="mr-2">{value ? "✓" : "✗"}</span>
                    {RetailFlagDisplayNames[key as RetailFlags]}
                  </p>
                ))}
            </div>
            <button
              className="mt-4 text-sm text-indigo-600 hover:underline"
              onClick={() => setSelectedRetailer(retailer)}
            >
              Edit
            </button>
          </li>
        ))}
      </ul>

      {selectedRetailer && (
        <EditRetailModal
          retail={selectedRetailer}
          onClose={() => setSelectedRetailer(null)}
          onSave={(updatedRetailer) => {
            setRetailers((prevRetailers) =>
              prevRetailers.map((retailer) =>
                retailer.id === updatedRetailer.id ? updatedRetailer : retailer
              )
            );
            setSelectedRetailer(null);
          }}
        />
      )}

      {/* Ny knapp for å legge til kategori */}
      <button
        className="mt-8 rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
        onClick={handleAddRetailor}
      >
        Add New Retail
      </button>
    </div>
  );
}
