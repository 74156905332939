import React, { useEffect, useRef, useState } from "react";

import {
  APIProvider,
  AdvancedMarker,
  ControlPosition,
  Map,
  MapControl,
  useAdvancedMarkerRef,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";

const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  autocompleteContainer: {
    boxSizing: "border-box" as const,
    width: "300px",
  },
  autocompleteControl: {
    margin: "24px",
    background: "#fff",
    boxSizing: "border-box" as const,
  },
  input: {
    width: "100%",
    height: "40px",
    padding: "0 12px",
    fontSize: "18px",
    boxSizing: "border-box" as const,
    border: "1px solid #ccc",
  },
};

interface PlaceAutocompleteAddressFieldProps {
  initialPosition: google.maps.LatLngLiteral;
  onLocationChange: (location: google.maps.LatLngLiteral) => void;
  onAddressChange?: (address: string) => void;
}

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  marker: google.maps.marker.AdvancedMarkerElement | null;
  onLocationChange: (location: google.maps.LatLngLiteral) => void;
  onAddressChange?: (address: string) => void;
}

const MapHandler = ({
  place,
  marker,
  onLocationChange,
  onAddressChange,
}: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else if (place.geometry?.location) {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    if (place.geometry?.location) {
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      marker.position = place.geometry.location;
      onLocationChange(location);
      if (onAddressChange) {
        onAddressChange(place.formatted_address || "");
      }
    }
  }, [map, place, marker, onLocationChange, onAddressChange]);

  return null;
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const PlaceAutocomplete = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div style={styles.autocompleteContainer}>
      <input ref={inputRef} style={styles.input} placeholder="Search for a location" />
    </div>
  );
};

export const PlaceAutocompleteAddressField: React.FC<
  PlaceAutocompleteAddressFieldProps
> = ({ initialPosition, onLocationChange, onAddressChange }) => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [currentLocation, setCurrentLocation] =
    useState<google.maps.LatLngLiteral>(initialPosition);

  const handleLocationChange = (location: google.maps.LatLngLiteral) => {
    setCurrentLocation(location);
    onLocationChange(location);
  };

  return (
    <div style={styles.container}>
      <div className="mt-4 space-y-2">
        <div className="text-sm">
          <span className="font-semibold">Coordinates:</span>{" "}
          {currentLocation.lat.toFixed(6)}, {currentLocation.lng.toFixed(6)}
        </div>
      </div>
      <APIProvider
        apiKey={"AIzaSyAO7CKqdqzsY9SHQY1ZXlmfsPSra_s3M-k"}
        solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
      >
        <div className="relative h-[300px] w-full">
          <Map
            mapId={"bf51a910020fa25a"}
            defaultZoom={15}
            defaultCenter={initialPosition}
            gestureHandling={"greedy"}
            disableDefaultUI={false}
            zoomControl={true}
            scrollwheel={true}
            streetViewControl={false}
            mapTypeControl={false}
            fullscreenControl={false}
          >
            <AdvancedMarker
              ref={markerRef}
              position={initialPosition}
              draggable={true}
              onClick={(e) => {
                if (e.latLng) {
                  handleLocationChange({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  });
                }
              }}
            />
          </Map>
          <MapControl position={ControlPosition.TOP}>
            <div style={styles.autocompleteControl}>
              <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
            </div>
          </MapControl>
          <MapHandler
            place={selectedPlace}
            marker={marker}
            onLocationChange={handleLocationChange}
            onAddressChange={onAddressChange}
          />
        </div>
      </APIProvider>
    </div>
  );
};
