import React, { useEffect, useState } from "react";

import { collection, doc, getDocs, query } from "firebase/firestore";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { getCurrentUserEmail } from "../auth";
import { db } from "../firebase";
import { Order } from "../pages/OrderPage";
import Retail from "../types/retail";

interface OrderSummary extends Order {
  pageName: string | null;
  retailName?: string;
}

interface RetailOption {
  value: string;
  label: string;
}

interface SortOption {
  value: string;
  label: string;
}

interface StatusOption {
  value: string;
  label: string;
}

const RetailOrders: React.FC = () => {
  const [userRetails, setUserRetails] = useState<Retail[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date("2023-10-15"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRetails, setSelectedRetails] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("newest");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const sortOptions: SortOption[] = [
    { value: "newest", label: "Newest First" },
    { value: "oldest", label: "Oldest First" },
    { value: "price-high", label: "Price (High to Low)" },
    { value: "price-low", label: "Price (Low to High)" },
    { value: "activity-asc", label: "Status Length (Shortest First)" },
    { value: "activity-desc", label: "Status Length (Longest First)" },
  ];

  const getPageName = (url: string | undefined) => {
    if (!url) return null;
    try {
      const urlWithoutHash = url.split("#")[0];
      const urlWithoutQuery = urlWithoutHash.split("?")[0];
      const segments = urlWithoutQuery.split("/").filter(Boolean);
      const lastSegment = segments[segments.length - 1];
      return lastSegment || null;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  const fetchUserRetails = async () => {
    const userEmail = await getCurrentUserEmail();
    if (!userEmail) return;

    const retailRef = collection(db, "retail");
    const retailSnapshot = await getDocs(retailRef);

    const userRetails = retailSnapshot.docs
      .map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          }) as Retail
      )
      .filter(
        (retail) =>
          retail.contactPerson?.email === userEmail ||
          retail.moderatorEmails?.includes(userEmail) ||
          retail.preVerifiedEmails?.includes(userEmail)
      );

    setUserRetails(userRetails);
    return userRetails;
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const userRetails = await fetchUserRetails();
      if (!userRetails?.length) {
        setOrders([]);
        return;
      }

      const retailIds = userRetails.map((retail) => retail.id);
      const ordersRef = collection(db, "b2cOrders");
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);

      const fetchedOrders = querySnapshot.docs
        .map((doc) => {
          const data = doc.data() as Order;
          // Only include orders that have a matching retailId
          if (data.retailId && retailIds.includes(data.retailId)) {
            const retail = userRetails.find((r) => r.id === data.retailId);
            return {
              ...data,
              id: doc.id,
              pageName: getPageName(data.orderCreationUrl),
              retailName: retail?.name,
            };
          }
          return null;
        })
        .filter((order): order is NonNullable<typeof order> => order !== null);

      setOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const sortOrders = (orders: OrderSummary[], sortType: string) => {
    const sorted = [...orders];
    switch (sortType) {
      case "newest":
        return sorted.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      case "oldest":
        return sorted.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      case "price-high":
        return sorted.sort(
          (a, b) => parseFloat(b.totalPrice || "0") - parseFloat(a.totalPrice || "0")
        );
      case "price-low":
        return sorted.sort(
          (a, b) => parseFloat(a.totalPrice || "0") - parseFloat(b.totalPrice || "0")
        );
      case "activity-asc":
        return sorted.sort(
          (a, b) => (a.activity?.length || 0) - (b.activity?.length || 0)
        );
      case "activity-desc":
        return sorted.sort(
          (a, b) => (b.activity?.length || 0) - (a.activity?.length || 0)
        );
      default:
        return sorted;
    }
  };

  const getUniqueStatuses = (orders: OrderSummary[]): StatusOption[] => {
    const statusSet = new Set<string>();

    orders.forEach((order) => {
      const lastActivity =
        order.activity && order.activity.length > 0
          ? order.activity[order.activity.length - 1].content
          : "No status";
      statusSet.add(lastActivity);
    });

    return Array.from(statusSet).map((status) => ({
      value: status,
      label: t(`workwearOrderPage.${status}`) || status,
    }));
  };

  const filteredOrders = sortOrders(
    orders.filter((order) => {
      const orderDate = new Date(order.date);
      const dateFilter = orderDate >= startDate && orderDate <= endDate;

      const searchFilter =
        searchQuery === "" ||
        order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.phone?.toLowerCase().includes(searchQuery.toLowerCase());

      const retailFilter =
        selectedRetails.length === 0 || selectedRetails.includes(order.retailId || "");

      const lastStatus =
        order.activity && order.activity.length > 0
          ? order.activity[order.activity.length - 1].content
          : "No status";
      const statusFilter =
        selectedStatuses.length === 0 || selectedStatuses.includes(lastStatus);

      return dateFilter && searchFilter && retailFilter && statusFilter;
    }),
    sortBy
  );

  const retailOptions: RetailOption[] = userRetails.map((retail) => ({
    value: retail.id,
    label: retail.name,
  }));

  const calculateStats = (orders: OrderSummary[]) => {
    const totalOrders = orders.length;
    const statusCounts = new Map<string, number>();

    orders.forEach((order) => {
      const lastActivity =
        order.activity && order.activity.length > 0
          ? order.activity[order.activity.length - 1].content
          : "No status";
      statusCounts.set(lastActivity, (statusCounts.get(lastActivity) || 0) + 1);
    });

    return {
      totalOrders,
      statusCounts,
    };
  };

  return (
    <div className="min-h-screen p-4">
      <div className="mb-8 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        <div className="rounded-lg border border-gray-200 bg-white p-3 shadow">
          <h3 className="text-xs font-medium text-gray-500">Total Orders</h3>
          <p className="mt-1 text-2xl font-semibold text-gray-900">
            {calculateStats(filteredOrders).totalOrders}
          </p>
        </div>

        {Array.from(calculateStats(orders).statusCounts.entries()).map(
          ([status, count]) => (
            <div
              key={status}
              onClick={() => {
                setSelectedStatuses((prev) =>
                  prev.includes(status)
                    ? prev.filter((s) => s !== status)
                    : [...prev, status]
                );
              }}
              className={`cursor-pointer rounded-lg border border-gray-200 p-3 shadow transition-colors ${
                selectedStatuses.includes(status)
                  ? "border-blue-300 bg-blue-100"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              <h3 className="line-clamp-1 text-xs font-medium text-gray-500">
                {t(`workwearOrderPage.${status}`) || status}
              </h3>
              <div className="mt-1 flex items-baseline justify-between">
                <p className="text-2xl font-semibold text-gray-900">{count}</p>
                <p className="text-xs text-gray-500">
                  {((count / orders.length) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          )
        )}
      </div>

      <div className="mb-8 space-y-4 border-b border-black pb-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-6">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Search order
            </label>
            <input
              type="text"
              placeholder="Search by customer name, phone, or order ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full rounded border border-black p-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">From Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => date && setStartDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">To Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => date && setEndDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {userRetails.length > 1 && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Filter by Retail
              </label>
              <Select
                isMulti
                options={retailOptions}
                onChange={(selected) =>
                  setSelectedRetails(selected.map((option) => option.value))
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          )}
          <div>
            <label className="block text-sm font-medium text-gray-700">Sorting</label>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="w-full rounded border border-black p-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-4"></div>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div key={order.id} className="border-b border-black p-4">
              <div className="flex flex-wrap justify-between gap-4">
                <div>
                  <h3 className="text-lg font-medium">
                    Order ID:{" "}
                    <a
                      href={`/orders/${order.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      {order.id.slice(0, 5)}
                    </a>
                  </h3>
                  <p>Customer: {order.contactInfo?.name || "N/A"}</p>
                  <p>Phone: {order.contactInfo?.phone}</p>
                  <p>
                    Date:{" "}
                    {new Date(order.date).toLocaleString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>Total Price: {order.totalPrice} NOK</p>
                  {order.retailName && <p>Retail: {order.retailName}</p>}
                </div>
                <div>
                  <p>
                    Status:{" "}
                    {order.activity && order.activity.length > 0
                      ? t(
                          `workwearOrderPage.${order.activity[order.activity.length - 1].content}`
                        )
                      : "No status"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RetailOrders;
