import React, { useEffect, useState } from "react";

import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { db } from "../../firebase";
import ModalTile from "../ModalTile";

interface Office {
  emailDomains: string[];
  location: string[];
}

interface OfficeData {
  [key: string]: Office;
}

type ModalType = "removeOffice" | "removeLocation" | "removeEmailDomain" | null;

interface ModalData {
  officeName: string;
  value?: string;
}

export default function OfficeDashboard() {
  const [offices, setOffices] = useState<OfficeData>({});
  const [selectedOffice, setSelectedOffice] = useState<string>("");
  const [newEmailDomain, setNewEmailDomain] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newOfficeName, setNewOfficeName] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [modalData, setModalData] = useState<ModalData | null>(null);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const officeRef = doc(db, "dynamicPages", "office");
        const officeDoc = await getDoc(officeRef);

        if (officeDoc.exists()) {
          setOffices(officeDoc.data() as OfficeData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching offices:", error);
        setLoading(false);
      }
    };

    fetchOffices();
  }, []);

  const handleAddEmailDomain = async () => {
    if (!selectedOffice || !newEmailDomain) return;

    try {
      const updatedOffices = { ...offices };
      if (!updatedOffices[selectedOffice].emailDomains) {
        updatedOffices[selectedOffice].emailDomains = [];
      }
      updatedOffices[selectedOffice].emailDomains.push(newEmailDomain);

      const officeRef = doc(db, "dynamicPages", "office");
      await updateDoc(officeRef, updatedOffices);

      setOffices(updatedOffices);
      setNewEmailDomain("");
    } catch (error) {
      console.error("Error adding email domain:", error);
    }
  };

  const handleAddLocation = async () => {
    if (!selectedOffice || !newLocation) return;

    try {
      const updatedOffices = { ...offices };
      if (!updatedOffices[selectedOffice].location) {
        updatedOffices[selectedOffice].location = [];
      }
      updatedOffices[selectedOffice].location.push(newLocation);

      const officeRef = doc(db, "dynamicPages", "office");
      await updateDoc(officeRef, updatedOffices);

      setOffices(updatedOffices);
      setNewLocation("");
    } catch (error) {
      console.error("Error adding location:", error);
    }
  };

  const handleRemoveEmailDomain = async (domain: string) => {
    setModalType("removeEmailDomain");
    setModalData({ officeName: selectedOffice, value: domain });
  };

  const handleRemoveLocation = async (location: string) => {
    setModalType("removeLocation");
    setModalData({ officeName: selectedOffice, value: location });
  };

  const handleAddOffice = async () => {
    if (!newOfficeName.trim()) return;

    try {
      const updatedOffices = { ...offices };
      updatedOffices[newOfficeName] = {
        emailDomains: [],
        location: [],
      };

      const officeRef = doc(db, "dynamicPages", "office");
      await updateDoc(officeRef, updatedOffices);

      setOffices(updatedOffices);
      setNewOfficeName("");
    } catch (error) {
      console.error("Error adding office:", error);
    }
  };

  const handleRemoveOffice = async (officeName: string) => {
    setModalType("removeOffice");
    setModalData({ officeName });
  };

  const handleConfirmRemove = async () => {
    if (!modalData) return;

    try {
      const updatedOffices = { ...offices };
      const officeRef = doc(db, "dynamicPages", "office");

      switch (modalType) {
        case "removeOffice":
          delete updatedOffices[modalData.officeName];
          if (selectedOffice === modalData.officeName) {
            setSelectedOffice("");
          }
          const updateObject = {
            [modalData.officeName]: deleteField(),
          };
          await updateDoc(officeRef, updateObject);
          break;

        case "removeLocation":
          if (modalData.value) {
            updatedOffices[modalData.officeName].location = updatedOffices[
              modalData.officeName
            ].location.filter((l) => l !== modalData.value);
            await updateDoc(officeRef, updatedOffices);
          }
          break;

        case "removeEmailDomain":
          if (modalData.value) {
            updatedOffices[modalData.officeName].emailDomains = updatedOffices[
              modalData.officeName
            ].emailDomains.filter((d) => d !== modalData.value);
            await updateDoc(officeRef, updatedOffices);
          }
          break;
      }

      setOffices(updatedOffices);
    } catch (error) {
      console.error("Error removing item:", error);
    }

    setModalType(null);
    setModalData(null);
  };

  const renderModalContent = () => {
    if (!modalData) return null;

    const getItemType = () => {
      switch (modalType) {
        case "removeOffice":
          return "office";
        case "removeLocation":
          return "location";
        case "removeEmailDomain":
          return "email domain";
        default:
          return "";
      }
    };

    return (
      <div className="p-4">
        <p className="mb-4">Are you sure you want to remove this {getItemType()}?</p>
        <p className="mb-4 font-medium">
          {modalType === "removeOffice" ? modalData.officeName : modalData.value}
        </p>
        <div className="flex justify-end gap-4">
          <button
            onClick={() => {
              setModalType(null);
              setModalData(null);
            }}
            className="rounded px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmRemove}
            className="rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700"
          >
            Remove
          </button>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="mb-6 text-2xl font-bold">Office Management</h1>

      <div className="mb-6 bg-white p-6 shadow sm:rounded-lg">
        <h2 className="mb-4 text-lg font-medium">Offices</h2>
        <div className="mb-4 flex gap-4">
          <input
            type="text"
            value={newOfficeName}
            onChange={(e) => setNewOfficeName(e.target.value)}
            placeholder="Add new office"
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
          <button
            onClick={handleAddOffice}
            className="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
          >
            Add Office
          </button>
        </div>
        <ul className="divide-y divide-gray-200">
          {Object.keys(offices)
            .sort()
            .map((office) => (
              <li key={office} className="flex items-center justify-between py-3">
                <button
                  onClick={() =>
                    setSelectedOffice(office === selectedOffice ? "" : office)
                  }
                  className={`text-left ${office === selectedOffice ? "font-bold text-indigo-600" : ""}`}
                >
                  {office}
                </button>
                <button
                  onClick={() => handleRemoveOffice(office)}
                  className="text-red-600 hover:text-red-800"
                >
                  Remove
                </button>
              </li>
            ))}
        </ul>
      </div>

      {selectedOffice && (
        <div className="space-y-6">
          <div className="bg-white p-6 shadow sm:rounded-lg">
            <h2 className="mb-4 text-lg font-medium">Email Domains</h2>
            <div className="mb-4 flex gap-4">
              <input
                type="text"
                value={newEmailDomain}
                onChange={(e) => setNewEmailDomain(e.target.value)}
                placeholder="Add new email domain"
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <button
                onClick={handleAddEmailDomain}
                className="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
              >
                Add Domain
              </button>
            </div>
            <ul className="divide-y divide-gray-200">
              {offices[selectedOffice]?.emailDomains?.map((domain) => (
                <li key={domain} className="flex items-center justify-between py-3">
                  <span>{domain}</span>
                  <button
                    onClick={() => handleRemoveEmailDomain(domain)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-white p-6 shadow sm:rounded-lg">
            <h2 className="mb-4 text-lg font-medium">Locations</h2>
            <div className="mb-4 flex gap-4">
              <input
                type="text"
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                placeholder="Add new location"
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <button
                onClick={handleAddLocation}
                className="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
              >
                Add Location
              </button>
            </div>
            <ul className="divide-y divide-gray-200">
              {offices[selectedOffice]?.location?.map((location) => (
                <li key={location} className="flex items-center justify-between py-3">
                  <span>{location}</span>
                  <button
                    onClick={() => handleRemoveLocation(location)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <ModalTile
        open={modalType !== null}
        onClose={() => {
          setModalType(null);
          setModalData(null);
        }}
        title="Confirm Removal"
      >
        {renderModalContent()}
      </ModalTile>
    </div>
  );
}
