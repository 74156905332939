import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChartPieIcon,
  ChatBubbleLeftIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  ScissorsIcon,
} from "@heroicons/react/24/outline";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

import { getCurrentUserEmail, getCurrentUserEmailAsync } from "../auth";
import NavBar from "../components/NavBar";
import FikseAdminOverview from "../components/admin/FikseAdminOverview";
import OfficeDashboard from "../components/admin/OfficeDashboard";
import OrderOverview from "../components/admin/OrderOverview";
import OrderOverview2 from "../components/admin/OrderOverviewWorkWear";
import Overview from "../components/admin/Overview";
import Reports from "../components/admin/Reports";
import RetailOverview from "../components/admin/RetailOverview";
import ServiceCategoriesOverview from "../components/admin/ServiceCategoriesOverview";
import TailorOverview from "../components/admin/TailorOverview";
import AdminDiscountOverview from "../components/admin/discount/AdminDiscountOverview";
import Log2 from "../dashboard/Log2";
import LogisticsOrderTailorDeligation from "../dashboard/LogisticsOrderTailorDeligation";
import PlayGround from "../dashboard/PlayGround";
import RetailAnalytics from "../dashboard/RetailAnalytics";
import RetailModerator from "../dashboard/RetailModerator";
import RetailOrders from "../dashboard/RetailOrders";
import SendSMS from "../dashboard/SendSMS";
import TailorModerator from "../dashboard/TailorModerator";
import { auth, db } from "../firebase";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";

export async function accountHasAccessToDashboard(email: string): Promise<boolean> {
  if (!email) return false;

  try {
    // Check all possible access roles in parallel
    const [
      isAdmin,
      isTailor,
      isRetailModerator,
      isWorkwear,
      isTailorModerator,
      isRetailEmployee,
    ] = await Promise.all([
      checkIfUserIsAdmin(email),
      checkIfUserIsTailor(email),
      checkIfUserIsRetailModerator(email),
      checkIfUserIsWorkwear(email),
      checkIfUserIsTailorModerator(email),
      checkIfUserIsRetailEmployee(email),
    ]);

    // Return true if the user has any valid role
    return (
      isAdmin ||
      isTailor ||
      isRetailModerator ||
      isRetailEmployee ||
      isWorkwear ||
      isTailorModerator
    );
  } catch (error) {
    console.error("Error checking dashboard access:", error);
    return false;
  }
}

const checkIfUserIsAdmin = async (email: string) => {
  try {
    const adminDocRef = doc(db, "dashboard", "admin");
    const adminDocSnapshot = await getDoc(adminDocRef);
    if (adminDocSnapshot.exists()) {
      const authorizedEmails = adminDocSnapshot.data().email || [];
      return authorizedEmails.includes(email);
    }
    return false;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};

const checkIfUserIsTailor = async (email: string) => {
  const tailorsRef = collection(db, "tailors");
  const snapshot = await getDocs(tailorsRef);
  for (const doc of snapshot.docs) {
    const preVerifiedEmails = doc.data().preVerifiedEmails || [];
    if (preVerifiedEmails.includes(email)) return true;
  }
  return false;
};

const checkIfUserIsRetailModerator = async (email: string) => {
  const retailRef = collection(db, "retail");
  const snapshot = await getDocs(retailRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (
      data.contactPerson?.email === email ||
      (data.moderatorEmails && data.moderatorEmails.includes(email))
    ) {
      return true;
    }
  }
  return false;
};

const checkIfUserIsWorkwear = async (email: string) => {
  try {
    const workwearDocRef = doc(db, "dashboard", "workwearDashboard");
    const workwearDocSnapshot = await getDoc(workwearDocRef);
    if (workwearDocSnapshot.exists()) {
      const authorizedEmails = workwearDocSnapshot.data().email || [];
      return authorizedEmails.includes(email);
    }
    return false;
  } catch (error) {
    console.error("Error checking workwear status:", error);
    return false;
  }
};

const checkIfUserIsTailorModerator = async (email: string) => {
  const tailorsRef = collection(db, "tailors");
  const snapshot = await getDocs(tailorsRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (
      data.contactPerson?.email === email ||
      (data.moderatorEmails && data.moderatorEmails.includes(email))
    ) {
      return true;
    }
  }
  return false;
};

const checkIfUserIsRetailEmployee = async (email: string) => {
  const retailRef = collection(db, "retail");
  const snapshot = await getDocs(retailRef);
  for (const doc of snapshot.docs) {
    const data = doc.data();
    if (data.preVerifiedEmails && data.preVerifiedEmails.includes(email)) {
      return true;
    }
  }
  return false;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationGroup {
  label: string;
  items: {
    id: string;
    name: string;
    href: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    current: boolean;
  }[];
}

const tailorNavigation: NavigationGroup[] = [
  {
    label: "Tailor",
    items: [
      {
        id: "tailor-dashboard",
        name: "Dashboard",
        href: "/dashboard/tailor",
        icon: ScissorsIcon,
        current: false,
      },
      {
        id: "tailor-orders",
        name: "Orders",
        href: "/dashboard/tailor/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "tailor-reports",
        name: "Reports",
        href: "/dashboard/tailor/reports",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "tailor-moderator",
        name: "Moderator",
        href: "/dashboard/tailor/moderator",
        icon: Cog6ToothIcon,
        current: false,
      },
    ],
  },
];

const workwearNavigation: NavigationGroup[] = [
  {
    label: "WorkWear",
    items: [
      {
        id: "workwear-orders",
        name: "Orders",
        href: "/dashboard/workwear/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "workwear-reports",
        name: "Future Features",
        href: "/dashboard/workwear/reports",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

const adminNavigation: NavigationGroup[] = [
  {
    label: "Fikse admin",
    items: [
      {
        id: "admin-overview",
        name: "Overview",
        href: "/dashboard/admin",
        icon: CalendarIcon,
        current: true,
      },
      {
        id: "admin-orders",
        name: "Orders",
        href: "/dashboard/admin/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-reports",
        name: "Reports",
        href: "/dashboard/admin/reports",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-tailor",
        name: "Repair partners",
        href: "/dashboard/admin/tailor",
        icon: ScissorsIcon,
        current: false,
      },
      {
        id: "admin-retail",
        name: "Retail partners",
        href: "/dashboard/admin/retail",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-service-categories",
        name: "Service categories",
        href: "/dashboard/admin/service-categories",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-discount",
        name: "Discount codes",
        href: "/dashboard/admin/discount",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-sms",
        name: "SMS",
        href: "/dashboard/admin/sms",
        icon: ChatBubbleLeftIcon,
        current: false,
      },
      {
        id: "admin-playground",
        name: "DevPlayground",
        href: "/dashboard/admin/playground",
        icon: Cog6ToothIcon,
        current: false,
      },
      {
        id: "admin-logistics",
        name: "Logistics",
        href: "/dashboard/admin/logistics",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-office",
        name: "Admin Office",
        href: "/dashboard/admin/office",
        icon: ClipboardDocumentIcon,
        current: false,
      },
    ],
  },
];

const retailNavigation: NavigationGroup[] = [
  {
    label: "Retail",
    items: [
      {
        id: "retail-moderator",
        name: "Overview",
        href: "/dashboard/retail",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "retail-orders",
        name: "Orders",
        href: "/dashboard/retail/orders",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "retail-analytics",
        name: "Analytics",
        href: "/dashboard/retail/analytics",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

export default function DashboardPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState<NavigationGroup[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Add initial access check
  useEffect(() => {
    const checkAccess = async () => {
      const email = await getCurrentUserEmailAsync();
      if (!email) {
        navigate("/dashboard");
        return;
      }

      const hasAccess = await accountHasAccessToDashboard(email);
      if (!hasAccess) {
        navigate("/dashboard");
        return;
      }

      // Check if the current path is accessible
      const path = location.pathname;
      const isPathAuthorized = await checkPathAuthorization(email, path);
      if (!isPathAuthorized) {
        navigate("/dashboard");
      }
    };

    checkAccess();
  }, [location.pathname]);

  // Function to check if user has access to specific path
  const checkPathAuthorization = async (email: string, path: string) => {
    const [
      isAdmin,
      isTailor,
      isRetailModerator,
      isWorkwear,
      isTailorModerator,
      isRetailEmployee,
    ] = await Promise.all([
      checkIfUserIsAdmin(email),
      checkIfUserIsTailor(email),
      checkIfUserIsRetailModerator(email),
      checkIfUserIsWorkwear(email),
      checkIfUserIsTailorModerator(email),
      checkIfUserIsRetailEmployee(email),
    ]);

    // Check path against user roles
    if (path.startsWith("/dashboard/admin") && !isAdmin) return false;
    if (path.startsWith("/dashboard/tailor") && !isTailor && !isTailorModerator)
      return false;
    if (path.startsWith("/dashboard/retail")) {
      if (path === "/dashboard/retail/orders") {
        return isRetailModerator || isRetailEmployee;
      }
      return isRetailModerator; // Only moderators can access other retail pages
    }
    if (path.startsWith("/dashboard/workwear") && !isWorkwear) return false;

    return true;
  };

  // Set up navigation once when component mounts or user changes
  useEffect(() => {
    const setupNavigation = async () => {
      const user = auth.currentUser;
      if (!user?.email) return;

      const [
        isAdmin,
        isTailor,
        isRetailModerator,
        isWorkwear,
        isTailorModerator,
        isRetailEmployee,
      ] = await Promise.all([
        checkIfUserIsAdmin(user.email),
        checkIfUserIsTailor(user.email),
        checkIfUserIsRetailModerator(user.email),
        checkIfUserIsWorkwear(user.email),
        checkIfUserIsTailorModerator(user.email),
        checkIfUserIsRetailEmployee(user.email),
      ]);

      if (
        !isAdmin &&
        !isTailor &&
        !isRetailModerator &&
        !isWorkwear &&
        !isTailorModerator &&
        !isRetailEmployee
      ) {
        navigate("/dashboard");
        return;
      }

      let finalNavigation: NavigationGroup[] = [];
      if (isAdmin) finalNavigation.push(...adminNavigation);
      if (isTailor || isTailorModerator) {
        const tailorNav = {
          ...tailorNavigation[0],
          items: [...tailorNavigation[0].items],
        };

        if (!isTailorModerator) {
          tailorNav.items = tailorNav.items.filter(
            (item) => item.id !== "tailor-moderator"
          );
        }

        finalNavigation.push(tailorNav);
      }
      if (isRetailModerator) {
        finalNavigation.push(...retailNavigation);
      } else if (isRetailEmployee) {
        // For retail employees, only show the orders page
        finalNavigation.push({
          label: "Retail",
          items: [
            {
              id: "retail-orders",
              name: "Orders",
              href: "/dashboard/retail/orders",
              icon: ClipboardDocumentIcon,
              current: false,
            },
          ],
        });
      }
      if (isWorkwear) finalNavigation.push(...workwearNavigation);

      // Update current status based on path
      const currentPath = location.pathname;
      finalNavigation = finalNavigation.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          current: item.href === currentPath,
        })),
      }));

      setNavigation(finalNavigation);
    };

    setupNavigation();
  }, [auth.currentUser]);

  // Update current navigation item when path changes
  useEffect(() => {
    const currentPath = location.pathname;
    setNavigation((prevNav) =>
      prevNav.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          current: item.href === currentPath,
        })),
      }))
    );
  }, [location.pathname]);

  const handleNavigationChange = (href: string) => {
    navigate(href);
  };

  const renderCurrentComponent = () => {
    const path = location.pathname;

    switch (path) {
      case "/dashboard/tailor":
        return <div>This is the Tailor Dashboard (empty for now).</div>;
      case "/dashboard/tailor/orders":
        return <OrderOverview />;
      case "/dashboard/workwear/orders":
        return <OrderOverview2 />;
      case "/dashboard/tailor/reports":
        return <Reports />;
      case "/dashboard/admin":
        return <Overview />;
      case "/dashboard/admin/orders":
        return <FikseAdminOverview />;
      case "/dashboard/admin/reports":
        return <Reports />;
      case "/dashboard/admin/tailor":
        return <TailorOverview />;
      case "/dashboard/admin/retail":
        return <RetailOverview />;
      case "/dashboard/admin/service-categories":
        return <ServiceCategoriesOverview />;
      case "/dashboard/admin/discount":
        return <AdminDiscountOverview />;
      case "/dashboard/admin/sms":
        return <SendSMS />;
      case "/dashboard/admin/playground":
        return <PlayGround />;
      case "/dashboard/retail":
        return <RetailModerator />;
      case "/dashboard/retail/orders":
        return <RetailOrders />;
      case "/dashboard/retail/analytics":
        return <RetailAnalytics />;
      case "/dashboard/admin/logistics":
        return <LogisticsOrderTailorDeligation />;
      case "/dashboard/tailor/moderator":
        return <TailorModerator />;
      case "/dashboard/admin/office":
        return <OfficeDashboard />;
      default:
        return <div></div>;
    }
  };

  // Render sidebar
  const renderSidebar = () => (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col">
        {navigation.map((group) => (
          <li key={group.label} className="mt-4">
            <h3 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
              {group.label}
            </h3>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
              {group.items.map((item, index) => (
                <li
                  key={item.id}
                  className={classNames(
                    "border-t border-black",
                    index === group.items.length - 1 ? "" : "border-b-0"
                  )}
                >
                  <a
                    href={item.href}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigationChange(item.href);
                    }}
                    className={classNames(
                      item.current
                        ? "bg-[#8CD7FF] text-[#ffffff]"
                        : "text-gray-700 hover:bg-[#c2e3f1] hover:text-[#ffffff]",
                      "group flex gap-x-3 p-2 text-sm font-semibold leading-6"
                    )}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );

  // Render mobile sidebar
  const renderMobileSidebar = () => (
    <nav className="mt-5 space-y-1 px-2">
      {navigation.map((group) => (
        <div key={group.label}>
          <h3 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
            {group.label}
          </h3>
          <ul role="list" className="mt-2 space-y-1">
            {group.items.map((item) => (
              <li key={item.id}>
                <a
                  href={item.href}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigationChange(item.href);
                    setSidebarOpen(false);
                  }}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    "group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 h-6 w-6 flex-shrink-0"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );

  return (
    <>
      <div>
        {/* Mobile sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[100] lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Cog6ToothIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <FikseLogo className="h-8 w-auto" />
                    </div>
                    {renderMobileSidebar()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true" />
            </div>
          </Dialog>
        </Transition.Root>

        {/* Desktop sidebar */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-[90] lg:flex lg:w-52 lg:flex-col">
          <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-stone-50 px-6 pb-4">
            <div className="flex h-40 shrink-0 flex-col items-center pt-8">
              <FikseLogo className="h-12 w-auto" />
              <span className="mt-2 text-lg font-bold text-fikseBlack">Dashboard</span>
            </div>
            {renderSidebar()}
          </div>
        </div>

        {/* Main content */}
        <div className="lg:pl-72">
          <div className="border-gray-20 sticky top-0 z-[80] flex h-16 shrink-0 items-center justify-between border-b bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <div className="flex w-full items-center justify-between lg:justify-end">
              <button
                type="button"
                className="shrink-0 p-2 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1">
                <NavBar />
              </div>
            </div>
          </div>

          <main className="mt-16">{renderCurrentComponent()}</main>
        </div>
      </div>
    </>
  );
}
