import { useCallback, useState } from "react";

import { Dialog } from "@headlessui/react";
import { Autocomplete, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GeoPoint, doc, updateDoc } from "firebase/firestore";

import { PlaceAutocompleteAddressField } from "../../components/PlaceAutocompleteAddressField";
import { db } from "../../firebase";
import Retail, { RetailFlagDisplayNames, RetailFlags } from "../../types/retail";

type MapOptions = google.maps.MapOptions;
type LatLngLiteral = google.maps.LatLngLiteral;

interface EditRetailModalProps {
  retail: Retail;
  onClose: () => void;
  onSave: (updatedRetail: Retail) => void;
}

const isContactPersonKey = (key: string): key is keyof Retail["contactPerson"] => {
  return ["name", "phone", "email"].includes(key);
};

const defaultCenter = {
  lat: 59.9139, // Oslo coordinates as default
  lng: 10.7522,
};

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const libraries: "places"[] = ["places"];

export function EditRetailModal({ retail, onClose, onSave }: EditRetailModalProps) {
  const defaultFlags = {
    [RetailFlags.CUSTOM_SMS_SENDER]: false,
    [RetailFlags.POSTAL_SERVICES]: false,
  };

  const [formData, setFormData] = useState({
    ...retail,
    marginPercentage: retail.margin ? Math.round(retail.margin * 100) : 0,
    flags: retail.flags || defaultFlags,
    smsAlias: retail.smsAlias || retail.name,
  });
  const [newEmail, setNewEmail] = useState("");
  const [newModeratorEmail, setNewModeratorEmail] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(
    retail.location
      ? { lat: retail.location.latitude, lng: retail.location.longitude }
      : defaultCenter
  );

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete | null>(
    null
  );
  const [isSearching, setIsSearching] = useState(false);

  const mapOptions: MapOptions = {
    disableDefaultUI: false,
    clickableIcons: true,
    scrollwheel: true,
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.startsWith("contactPerson.")) {
      const key = name.split(".")[1];
      if (isContactPersonKey(key)) {
        setFormData({
          ...formData,
          contactPerson: { ...formData.contactPerson, [key]: value },
        });
      }
    } else if (name === "addresses") {
      setFormData({
        ...formData,
        addresses: value.split(",").map((address) => address.trim()),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    const lowerCaseEmail = newEmail.toLowerCase();
    if (lowerCaseEmail && !formData.preVerifiedEmails.includes(lowerCaseEmail)) {
      setFormData({
        ...formData,
        preVerifiedEmails: [...formData.preVerifiedEmails, lowerCaseEmail],
      });
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setFormData({
      ...formData,
      preVerifiedEmails: formData.preVerifiedEmails.filter(
        (email) => email !== emailToRemove
      ),
    });
  };

  const handleAddModeratorEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    const lowerCaseEmail = newModeratorEmail.toLowerCase();
    if (lowerCaseEmail && !formData.moderatorEmails?.includes(lowerCaseEmail)) {
      setFormData({
        ...formData,
        moderatorEmails: [...(formData.moderatorEmails || []), lowerCaseEmail],
      });
      setNewModeratorEmail("");
    }
  };

  const handleRemoveModeratorEmail = (emailToRemove: string) => {
    setFormData({
      ...formData,
      moderatorEmails:
        formData.moderatorEmails?.filter((email) => email !== emailToRemove) || [],
    });
  };

  const handleAddAddress = (e: React.MouseEvent) => {
    e.preventDefault();
    if (newAddress && !formData.addresses.includes(newAddress)) {
      setFormData({
        ...formData,
        addresses: [...formData.addresses, newAddress],
      });
      setNewAddress("");
    }
  };

  const handleRemoveAddress = (addressToRemove: string) => {
    setFormData({
      ...formData,
      addresses: formData.addresses.filter((address) => address !== addressToRemove),
    });
  };

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
    }
  }, []);

  const handlePlaceSelect = () => {
    const place = searchBox?.getPlace();
    if (place?.geometry?.location) {
      const newPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setMarkerPosition(newPosition);
      map?.panTo(newPosition);
      map?.setZoom(15);
    }
  };

  const handleMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const percentage = Math.max(0, Math.min(100, parseInt(e.target.value) || 0));
    setFormData({
      ...formData,
      marginPercentage: percentage,
      margin: percentage / 100,
    });
  };

  const handleFlagChange = (flagKey: RetailFlags, value: boolean) => {
    setFormData((prev) => ({
      ...prev,
      flags: {
        ...prev.flags,
        [flagKey]: value,
      },
    }));
  };

  const handleSmsAliasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 11); // Enforce 11 character limit
    setFormData((prev) => ({
      ...prev,
      smsAlias: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const retailRef = doc(db, "retail", formData.id);

    const location = new GeoPoint(markerPosition.lat, markerPosition.lng);

    const updateData = {
      ...formData,
      location,
      margin: formData.marginPercentage / 100, // Convert percentage to decimal
    };
    delete (updateData as any).marginPercentage; // Remove temporary percentage field

    await updateDoc(retailRef, updateData);
    onSave({ ...updateData, location });
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Edit Retailer
              </Dialog.Title>
              <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Org Number
                  </label>
                  <input
                    type="text"
                    name="orgNumber"
                    value={formData.orgNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Margin (%)
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={formData.marginPercentage}
                    onChange={handleMarginChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Addresses
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={newAddress}
                      onChange={(e) => setNewAddress(e.target.value)}
                      className="flex-1 rounded border border-gray-300 px-2 py-1 text-sm"
                      placeholder="Add address"
                    />
                    <button
                      type="button"
                      onClick={handleAddAddress}
                      className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {formData.addresses.map((address, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-50 px-3 py-2"
                      >
                        <span className="text-sm">{address}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveAddress(address)}
                          className="ml-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-medium text-gray-700">
                      {RetailFlagDisplayNames[RetailFlags.POSTAL_SERVICES]}
                    </label>
                    <button
                      type="button"
                      onClick={() =>
                        handleFlagChange(
                          RetailFlags.POSTAL_SERVICES,
                          !formData.flags[RetailFlags.POSTAL_SERVICES]
                        )
                      }
                      className={`${
                        formData.flags[RetailFlags.POSTAL_SERVICES]
                          ? "bg-blue-500"
                          : "bg-gray-200"
                      } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                    >
                      <span
                        className={`${
                          formData.flags[RetailFlags.POSTAL_SERVICES]
                            ? "translate-x-6"
                            : "translate-x-1"
                        } mt-1 inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                      />
                    </button>
                  </div>
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Name
                  </label>
                  <input
                    type="text"
                    name="contactPerson.name"
                    value={formData.contactPerson.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Phone
                  </label>
                  <input
                    type="text"
                    name="contactPerson.phone"
                    value={formData.contactPerson.phone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Email
                  </label>
                  <input
                    type="email"
                    name="contactPerson.email"
                    value={formData.contactPerson.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Personnel
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value.toLowerCase().trim())}
                      className="flex-1 rounded border border-gray-300 px-2 py-1 text-sm"
                      placeholder="Add email address"
                    />
                    <button
                      type="button"
                      onClick={handleAddEmail}
                      className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {formData.preVerifiedEmails.map((email, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-50 px-3 py-2"
                      >
                        <span className="text-sm">{email}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveEmail(email)}
                          className="ml-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Moderators
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="email"
                      value={newModeratorEmail}
                      onChange={(e) =>
                        setNewModeratorEmail(e.target.value.toLowerCase().trim())
                      }
                      className="flex-1 rounded border border-gray-300 px-2 py-1 text-sm"
                      placeholder="Add moderator email address"
                    />
                    <button
                      type="button"
                      onClick={handleAddModeratorEmail}
                      className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {formData.moderatorEmails?.map((email, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-50 px-3 py-2"
                      >
                        <span className="text-sm">{email}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveModeratorEmail(email)}
                          className="ml-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="mb-2 text-lg font-semibold">Location</h3>
                  <PlaceAutocompleteAddressField
                    initialPosition={markerPosition}
                    onLocationChange={(location: LatLngLiteral) => {
                      setMarkerPosition(location);
                      if (map) {
                        map.panTo(location);
                        map.setZoom(15);
                      }
                    }}
                  />
                </div>
                <div className="mb-4">
                  <h3 className="mb-2 font-semibold">Functions</h3>
                  <div className="space-y-4">
                    <div>
                      <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium text-gray-700">
                          {RetailFlagDisplayNames[RetailFlags.POSTAL_SERVICES]}
                        </label>
                        <button
                          type="button"
                          onClick={() =>
                            handleFlagChange(
                              RetailFlags.POSTAL_SERVICES,
                              !formData.flags[RetailFlags.POSTAL_SERVICES]
                            )
                          }
                          className={`${
                            formData.flags[RetailFlags.POSTAL_SERVICES]
                              ? "bg-blue-500"
                              : "bg-gray-200"
                          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                          <span
                            className={`${
                              formData.flags[RetailFlags.POSTAL_SERVICES]
                                ? "translate-x-6"
                                : "translate-x-1"
                            } mt-1 inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium text-gray-700">
                          {RetailFlagDisplayNames[RetailFlags.CUSTOM_SMS_SENDER]}
                        </label>
                        <button
                          type="button"
                          onClick={() =>
                            handleFlagChange(
                              RetailFlags.CUSTOM_SMS_SENDER,
                              !formData.flags[RetailFlags.CUSTOM_SMS_SENDER]
                            )
                          }
                          className={`${
                            formData.flags[RetailFlags.CUSTOM_SMS_SENDER]
                              ? "bg-blue-500"
                              : "bg-gray-200"
                          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                          <span
                            className={`${
                              formData.flags[RetailFlags.CUSTOM_SMS_SENDER]
                                ? "translate-x-6"
                                : "translate-x-1"
                            } mt-1 inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                          />
                        </button>
                      </div>
                      {formData.flags[RetailFlags.CUSTOM_SMS_SENDER] && (
                        <div className="mt-2">
                          <div className="relative">
                            <input
                              type="text"
                              value={formData.smsAlias}
                              onChange={handleSmsAliasChange}
                              maxLength={11}
                              placeholder="Enter custom sender name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <div className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500">
                              {formData.smsAlias?.length || 0}/11
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-between space-x-4 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded bg-[#8CD7FF] px-3 py-2 text-sm font-semibold shadow-sm hover:bg-[#7bc5ee]"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex w-full justify-center rounded border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
