import { useEffect, useState } from "react";

import { format } from "date-fns";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { db } from "../../../firebase";
import EditDiscountModal from "./EditDiscountModal";

// Modal for redigering

interface Discount {
  id: string;
  name: string;
  value: number;
  count: number;
  used: number;
  timed: boolean;
  validFrom: { seconds: number; nanoseconds: number } | null;
  validTo: { seconds: number; nanoseconds: number } | null;
}

export default function AdminDiscountOverview() {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [newDiscount, setNewDiscount] = useState({
    name: "",
    value: 0,
    count: 0,
    used: 0,
    timed: false,
    validFrom: new Date(),
    validTo: new Date(),
  });
  const [editDiscount, setEditDiscount] = useState<Discount | null>(null);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "discount"));
        const discountList: Discount[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Discount, "id">),
        }));
        setDiscounts(discountList);
      } catch (error) {
        console.error("Error fetching discounts: ", error);
      }
    };

    fetchDiscounts();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setNewDiscount((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? checked : name === "name" ? value.toUpperCase() : value,
    }));
  };

  const handleDateChange = (date: Date | null, field: "validFrom" | "validTo") => {
    if (date) {
      setNewDiscount((prev) => ({
        ...prev,
        [field]: date,
      }));
    }
  };

  const handleAddDiscount = async () => {
    try {
      // Sjekk om det allerede finnes en rabatt med samme navn
      const q = query(collection(db, "discount"), where("name", "==", newDiscount.name));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        alert(
          "A discount with this name already exists. Please choose a different name."
        );
        return; // Stopp prosessen hvis det finnes en rabatt med samme navn
      }

      // Legg til den nye rabatten
      await addDoc(collection(db, "discount"), {
        ...newDiscount,
        validFrom: newDiscount.timed ? newDiscount.validFrom : null,
        validTo: newDiscount.timed ? newDiscount.validTo : null,
        creation: Timestamp.now(),
      });

      alert("Discount code added successfully!");
      setNewDiscount({
        name: "",
        value: 0,
        count: 0,
        used: 0,
        timed: false,
        validFrom: new Date(),
        validTo: new Date(),
      }); // Reset input fields
    } catch (error) {
      console.error("Error adding discount: ", error);
      alert("An error occurred while adding the discount.");
    }
  };

  const handleDelete = async (id: string) => {
    const confirm = window.confirm("Are you sure you want to delete this discount?");
    if (confirm) {
      try {
        await deleteDoc(doc(db, "discount", id));
        setDiscounts((prev) => prev.filter((discount) => discount.id !== id));
        alert("Discount deleted successfully!");
      } catch (error) {
        console.error("Error deleting discount: ", error);
      }
    }
  };

  const handleEdit = (discount: Discount) => {
    setEditDiscount(discount); // Åpner modal
  };

  const isValid = (discount: Discount): boolean => {
    const now = new Date();
    const isTimedValid =
      discount.timed &&
      discount.validFrom &&
      discount.validTo &&
      now >= new Date(discount.validFrom.seconds * 1000) &&
      now <= new Date(discount.validTo.seconds * 1000);

    const hasRemaining = discount.count > 0;
    return hasRemaining && (!discount.timed || Boolean(isTimedValid));
  };

  return (
    <div className="flex">
      {/* Left side: Discounts overview */}
      <div className="m-12 mx-8 flex-1">
        <h2 className="mb-4 text-2xl font-bold text-gray-900">Discounts</h2>
        <ul role="list" className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {discounts.map((discount) => (
            <li
              key={discount.id}
              className={`relative overflow-hidden rounded-lg border p-6 shadow-sm ${
                isValid(discount) ? "border-green-300 bg-green-100" : "bg-gray-100"
              }`}
            >
              <div className="absolute right-4 top-4 flex space-x-2">
                <button
                  onClick={() => handleEdit(discount)}
                  className="rounded-full border border-gray-300 bg-gray-100 px-3 py-1 text-xs font-semibold text-blue-600 shadow hover:bg-blue-50"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(discount.id)}
                  className="rounded-full border border-red-300 bg-red-100 px-3 py-1 text-xs font-semibold text-red-600 shadow hover:bg-red-50"
                >
                  Delete
                </button>
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-900">{discount.name}</h3>
                <p className="mt-2 text-sm text-gray-500">
                  <strong>Value:</strong>{" "}
                  {discount.value < 1
                    ? `${Math.round(discount.value * 100)}%`
                    : `${discount.value}kr`}
                </p>
                <p className="text-sm text-gray-500">
                  <strong>Remaining:</strong>{" "}
                  {discount.used === 0
                    ? discount.count + " (Never used)"
                    : discount.count + " (" + discount.used + " used)"}
                </p>
                <p className="text-sm text-gray-500">
                  <strong>Timed:</strong> {discount.timed ? "Yes" : "No"}
                </p>
                {discount.timed && (
                  <>
                    <p className="text-sm text-gray-500">
                      <strong>Valid From:</strong>{" "}
                      {discount.validFrom &&
                        format(new Date(discount.validFrom.seconds * 1000), "PPP")}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Valid To:</strong>{" "}
                      {discount.validTo &&
                        format(new Date(discount.validTo.seconds * 1000), "PPP")}
                    </p>
                  </>
                )}
              </div>
              {isValid(discount) && (
                <div className="absolute bottom-4 right-4">
                  <span className="inline-block rounded-full bg-white px-3 py-1 text-xs font-semibold text-green-600 shadow">
                    Valid
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Edit Discount Modal */}
      {editDiscount && (
        <EditDiscountModal
          discount={editDiscount}
          onClose={() => setEditDiscount(null)}
          onSave={(updatedDiscount) => {
            setDiscounts((prev) =>
              prev.map((d) => (d.id === updatedDiscount.id ? updatedDiscount : d))
            );
            setEditDiscount(null);
          }}
        />
      )}

      {/* Right side: Create new discount */}
      <div className="w-80 border-l border-gray-200 bg-gray-50 p-6">
        <h2 className="mb-4 text-xl font-bold text-gray-900">Add new discount</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={newDiscount.name}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Value</label>
            <input
              type="number"
              name="value"
              value={newDiscount.value}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Count</label>
            <input
              type="number"
              name="count"
              value={newDiscount.count}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Validity type
            </label>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setNewDiscount((prev) => ({ ...prev, timed: true }))}
                className={`rounded-md px-4 py-2 font-bold ${
                  newDiscount.timed ? "bg-green-600 text-white" : "bg-gray-200"
                }`}
              >
                Timed validity
              </button>
              <button
                onClick={() => setNewDiscount((prev) => ({ ...prev, timed: false }))}
                className={`rounded-md px-4 py-2 font-bold ${
                  !newDiscount.timed ? "bg-green-600 text-white" : "bg-gray-200"
                }`}
              >
                Always valid
              </button>
            </div>
          </div>
          {newDiscount.timed && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Valid from:
                </label>
                <DatePicker
                  selected={newDiscount.validFrom}
                  onChange={(date) => {
                    if (date) handleDateChange(date, "validFrom");
                  }}
                  className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Valid to:
                </label>
                <DatePicker
                  selected={newDiscount.validTo}
                  onChange={(date) => {
                    if (date) handleDateChange(date, "validTo");
                  }}
                  className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </>
          )}
          <button
            onClick={handleAddDiscount}
            className="w-full rounded-md bg-gray-600 px-4 py-2 font-bold text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Add Discount
          </button>
        </div>
      </div>
    </div>
  );
}
