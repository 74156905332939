// components/OrderCardDetails.tsx
import React from "react";

import { useTranslation } from "react-i18next";

import { ActivityItem, Order, brandPriceVisibility } from "../OrderPage";

// Import the Order interface from the original file

const TotalPrice: React.FC<{ order: Order }> = ({ order }) => {
  return (
    <>
      <span className="ml-1">{order.totalPrice}</span>
    </>
  );
};

const OrderCardDetails: React.FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="mt-0 block border-black p-5 text-base md:hidden">
        {/*t("workwearOrderPage.itemsInOrder")*/}
      </h3>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <p className="border-b border-black pb-4 pl-4 text-base">
            {t("workwearOrderPage.orderDetails")}
          </p>

          {order.deliveryMethod && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.delivery")}:</span>
              <span className="ml-1">{order.deliveryMethod}</span>
            </p>
          )}

          {order.deliveryAddress && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.deliverdTo")}:</span>
              <span className="ml-1">{order.deliveryAddress}</span>
            </p>
          )}

          {order.retrieveMethod && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.retrieval")}:</span>
              <span className="ml-1">{order.retrieveMethod}</span>
            </p>
          )}

          {order.retrieveAddress && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.retrieveAddress")}:</span>
              <span className="ml-1">{order.retrieveAddress}</span>
            </p>
          )}

          {order.deliveryDepartment && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.department")}:</span>
              <span className="ml-1">{order.deliveryDepartment}</span>
            </p>
          )}

          {order.contactInfo.name && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.name")}:</span>
              <span className="ml-1">{order.contactInfo.name}</span>
            </p>
          )}

          {order.brand && (
            <p className="border-b border-black p-4 text-base">
              <span className="ml-1">{order.brand}</span>
            </p>
          )}

          {order.email && !order.brand && (
            <p className="border-b border-black p-4 text-base">
              <span className="ml-1">{order.email}</span>
            </p>
          )}

          {order.contactInfo.phone && (
            <p className="border-b border-black p-4 text-base">
              <span className="ml-1">{order.contactInfo.phone}</span>
            </p>
          )}

          {order.resourceNumber && (
            <p className="border-b border-black p-4 text-base">
              <span className="">{t("workwearOrderPage.resourceNumber")}:</span>
              <span className="ml-1">{order.resourceNumber}</span>
            </p>
          )}

          {order.claim && (
            <p className="border-b border-black bg-fikseBeige p-4 text-base">
              <span className="">{t("Claim")}:</span>
              <span className="ml-1">{order.claimNote}</span>
            </p>
          )}
          {
            /*Legger til totalsum hvis det er mer enn en ordre */

            brandPriceVisibility(order.retailId) &&
              (order.orderInfo.length > 1 || order.usePostalService) && (
                <p className="border-b border-black p-4 text-base">
                  <span className="">{t("estimatedTotal")}:</span>
                  <TotalPrice order={order} />
                </p>
              )
          }
        </div>
      </div>
    </>
  );
};

export default OrderCardDetails;
