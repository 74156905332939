import React, { useEffect, useState } from "react";

import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { db } from "../firebase";

const OrderIDLookup: React.FC = () => {
  const { OrderIdSlug } = useParams<{ OrderIdSlug: string }>();
  const [orderCollection, setOrderCollection] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const findOrder = async () => {
      if (!OrderIdSlug) return;

      // Extract first 5 letters for query
      const searchPrefix = OrderIdSlug.slice(0, 5).toUpperCase();

      // Check workwearOrders
      const workwearQuery = query(
        collection(db, "workwearOrders"),
        where("__name__", ">=", searchPrefix),
        where("__name__", "<=", searchPrefix + "\uf8ff")
      );

      // Check b2cOrders
      const b2cQuery = query(
        collection(db, "b2cOrders"),
        where("__name__", ">=", searchPrefix),
        where("__name__", "<=", searchPrefix + "\uf8ff")
      );

      try {
        // Search both collections
        const [workwearDocs, b2cDocs] = await Promise.all([
          getDocs(workwearQuery),
          getDocs(b2cQuery),
        ]);

        if (!b2cDocs.empty) {
          setOrderCollection("b2cOrders");
          const fullOrderId = b2cDocs.docs[0].id;
          navigate(`/orders/${fullOrderId}`);
        } else if (!workwearDocs.empty) {
          setOrderCollection("workwearOrders");
          const fullOrderId = workwearDocs.docs[0].id;
          navigate(`/orders/workwear/${fullOrderId}`);
        } else {
          // Order not found in either collection
          console.log("Order not found");
          navigate("/order-not-found");
        }
      } catch (error) {
        console.error("Error searching for order:", error);
        navigate("/error");
      }
    };

    findOrder();
  }, [OrderIdSlug, navigate]);

  // No need to render anything as we're redirecting
  return null;
};

export default OrderIDLookup;
